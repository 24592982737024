<template>
  <v-combobox
    :search-input.sync="search"
    :items="itemsValidated"
    v-bind="$attrs"
    :value="value"
    @change="onSelect"
  >
    <template v-slot:no-data>
      <div class="pa-2" v-if="itemsValidated.length">
        No results matching "<strong>{{ search }}</strong
        >". Press <kbd class="mx-1">enter</kbd> to create a new one
      </div>
    </template>
  </v-combobox>
</template>
<script>
export default {
  props: {
    items: {
      type: Array
    },
    value: {},
    defaultToSingularOption: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    search: '',
    defaultPreItemsData: [],
    defaultPostItems: []
  }),
  computed: {
    defaultPreItems: {
      set(data) {
        this.defaultPreItems = data
      },
      get() {
        let d = [...this.defaultPreItemsData]
        if (this.items.length) {
          d.unshift({
            header: 'Select an option from below or type one above'
          })
        }
        return d
      }
    },
    itemsValidated() {
      return [...this.defaultPreItems, ...this.items, ...this.defaultPostItems]
    }
  },
  beforeMount() {},
  methods: {
    onSelect(value) {
      this.$emit('input', value)
    },
    insertCustomValue() {
      if (!this.value) {
        return
      }
      let items = [...items, ...this.defaultPostItems]
      let found = items.some(e => e == this.value)
      if (!found) {
        this.defaultPostItems.push(this.value)
      }
    },
    onItemsChange() {
      if (this.defaultToSingularOption) {
        if (this.items.length == 1 && !this.value) {
          this.onSelect(this.items[0])
        }
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: 'insertCustomValue'
    },
    items: {
      immediate: true,
      handler: 'onItemsChange'
    }
  }
}
</script>

<style></style>
