<template>
  <div>
    <v-card v-show="show" max-width="300" min-width="150">
      <v-card-text>
        <div v-if="!detail" class="text-center">
          <v-progress-circular
            width="3"
            size="30"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
        <div v-else class="hover-container">
          <div class="text-center">
            <strong>
              <span v-html="detail.caption"></span>
            </strong>
          </div>
          <div class="mt-2">
            <small v-html="detail.subCaption"></small>
          </div>
          <v-divider
            v-if="(detail.caption || detail.subCaption) && comments"
            class="my-1"
          ></v-divider>
          <div v-show="comments > 0" class="text-center">
            <span class="primary--text">{{ comments }}</span>
            <v-icon color="primary" small>mdi-comment</v-icon>
          </div>
          <div v-if="showDateShared" class="text-right grey--text">
            {{ detail.createdAt | date }}
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  filters: {
    date(d) {
      try {
        return moment(d.toDate()).format('YYYY-MM-DD')
      } catch {
        return ''
      }
    }
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    galleryCommentRef: {
      type: Object,
      required: true
    },
    galleryRef: {
      type: Object,
      required: true
    },
    showDateShared: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      detail: null,
      comments: 0
    }
  },
  computed: {
    show() {
      // if (!this.detail) {
      //   return true
      // }
      if (
        this.detail &&
        (this.detail.caption || this.detail.subCaption || this.comments)
      ) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    id: {
      handler(val) {
        if (val) {
          this.getDetail(val)
          this.getComments(val)
        }
      },
      immediate: true
    }
  },
  methods: {
    getComments(id) {
      this.galleryCommentRef
        .where('gallery', '==', id)
        .get()
        .then(res => {
          this.comments = res.size
        })
    },
    getDetail(id) {
      this.galleryRef
        .doc(id)
        .get()
        .then(r => {
          this.detail = r.data()
        })
    }
  }
}
</script>

<style></style>
