import colors from '../../design/_colors_hvac.scss'
export default {
  state: {
    HVACData: {
      data: [],
      information: null
    },
    showHVACForm: true,
    HVACSources: [
      {
        text: 'Oil',
        shortName: 'Oil',
        value: 'oil',
        unit: 'Gallons',
        unitShort: 'gals',
        unitPerItem: 'gal',
        icon: '$oil',
        unit_field: true,
        style: {
          color: colors['hvac-oil'],
          dark: true
        },
        btu: 137381
      },
      {
        text: 'Propane',
        shortName: 'Prpn',
        value: 'propane',
        unit: 'Gallons',
        unitShort: 'gals',
        unitPerItem: 'gal',
        icon: '$propane',
        unit_field: true,
        style: {
          color: colors['hvac-propane'],
          dark: true
        },
        btu: 91452
      },
      {
        text: 'Natural Gas',
        shortName: 'NGas',
        value: 'natural_gas',
        unit: 'Therms',
        unitShort: 'therms',
        unitPerItem: 'therm',
        unit_field: true,
        style: {
          color: colors['hvac-natural_gas'],
          dark: true
        },
        btu: 100000
      },
      {
        text: 'Electric',
        shortName: 'Elec',
        value: 'electric',
        unit: 'Kilowatt-Hours',
        unitPerItem: 'kWh',
        unitShort: 'kWh',
        icon: '$electric',
        unit_field: true,
        style: {
          color: colors['hvac-electric'],
          dark: true
        },
        btu: 3412
      },
      {
        text: 'Wood',
        shortName: 'Wood',
        value: 'wood',
        unit: 'Cords',
        unitShort: 'cords',
        unitPerItem: 'cord',
        icon: '$wood',
        unit_field: true,
        style: {
          color: colors['hvac-wood'],
          dark: true
        },
        btu: 25560000
      },
      {
        text: 'Pellet',
        shortName: 'Pellet',
        value: 'pellet',
        unit: 'Lbs',
        unitShort: 'Lbs',
        unitPerItem: 'lb',
        unit_field: true,
        style: {
          color: colors['hvac-pellet'],
          dark: true
        },
        btu: 7750
      },
      {
        text: 'Other',
        shortName: 'Other',
        value: 'other',
        unit: 'Dollar',
        unit_field: false,
        style: {
          color: colors['hvac-other'],
          dark: true
        }
      }
    ],
    HVACVendors: [
      { company: 'Cape Cod Oil', services: ['oil', 'propane'] },
      { company: 'FA Days', services: ['propane'] },
      { company: 'Eversource', services: ['electric'] },
      { company: 'Cape Light Compact', services: ['electric'] },
      { company: 'National Grid', services: ['natural_gas'] },
      { company: 'Snow’s', services: ['propane'] }
    ],
    HVACDefaultSources: {
      oil: {},
      propane: {},
      natural_gas: {},
      electric: {},
      wood: {},
      pellet: {},
      other: {}
    },
    HVACLoading: true,
    HVACFilter: ''
  },
  getters: {
    HVACData(state) {
      return state.HVACData
    },
    HVACInformation(state) {
      return state.HVACData.information
    },
    HVACDelivery(state) {
      let res = state.HVACData.data
      res = res.map((e, i) => {
        e.index = i
        return e
      })
      return res
    },
    HVACVendors(state) {
      return state.HVACVendors
    },
    HVACSources(state) {
      return state.HVACSources
    },
    HVACDefaultSources(state) {
      return state.HVACDefaultSources
    },
    HVACLoading(state) {
      return state.HVACLoading
    },
    HVACFilter(state) {
      return state.HVACFilter
    },
    filteredHVAC(state, getters) {
      let res = getters.HVACDelivery
      if (state.HVACFilter) {
        res = res.filter(e => e.fuel == state.HVACFilter)
      }
      return res
    },
    availableHVACSources(state) {
      let sources = new Set()
      state.HVACData.data.map(e => {
        sources.add(e.fuel)
        return e
      })
      return [...sources]
    },
    showHVACForm(state) {
      return state.showHVACForm
    }
  },
  mutations: {
    addHVACDelivery(state, item) {
      state.HVACData.data.push(item)
    },
    setHVACDelivery(state, data) {
      state.HVACData.data = data
      state.HVACLoading = false
    },
    updateHVACDelivery(state, { data, index }) {
      state.HVACData.data.splice(index, 1, data)
    },
    removeHVACDelivery(state, index) {
      state.HVACData.data.splice(index, 1)
    },
    setHVACInformation(state, data) {
      state.HVACData.information = data
      state.HVACLoading = false
    },
    updateHVACInformation(state, data) {
      state.HVACData.information = data
    },
    setHVACData(state, data) {
      state.HVACData = data
      state.HVACLoading = false
    },
    HVACLoading(state, val) {
      state.HVACLoading = val
    },
    setHVACFilter(state, filter) {
      state.HVACFilter = filter
    },
    setShowHVACForm(state, val) {
      state.showHVACForm = val
    }
  },
  actions: {
    resetHVAC({ commit }) {
      commit('setHVACDelivery', [])
      commit('setHVACInformation', null)
      commit('setHVACFilter', '')
      commit('setShowHVACForm', false)
    }
  }
}
