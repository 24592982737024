import firebase from 'firebase/app'
import '@/plugins/firebase'
import 'firebase/firestore'
import 'firebase/storage'

const db = firebase.firestore()

const parcelRef = db.collection('parcels')
const taxRef = db.collection('taxes')
const userRef = db.collection('users')
const parcelTypesRef = db.collection('parcel_types')
const featureRef = db.collection('features')
const contactRef = db.collection('contact')
const waterTestRef = db.collection('water_tests')
const addressRef = db.collection('addresses')
const storageRef = firebase.storage()
const storage = firebase.storage()
const eventRef = db.collection('events')
const septicRef = db.collection('septic_systems')
const teamRef = db.collection('teams')
const hvacRef = db.collection('hvac')
const solarRef = db.collection('solar')
const adminDataRef = db.collection('admin_data')

const getRef = feature => {
  let ref
  switch (feature) {
    case 'tax':
      ref = taxRef
      break
    case 'water':
      ref = waterTestRef
      break
    case 'septic':
      ref = septicRef
      break
    case 'solar':
      ref = solarRef
      break
    case 'hvac':
      ref = hvacRef
      break
  }
  return ref
}

export {
  firebase,
  db,
  parcelRef,
  taxRef,
  userRef,
  parcelTypesRef,
  featureRef,
  contactRef,
  waterTestRef,
  addressRef,
  storageRef,
  eventRef,
  septicRef,
  teamRef,
  hvacRef,
  solarRef,
  adminDataRef,
  storage,
  getRef
}
