import firebase from 'firebase/app'
import Vue from 'vue'
import { firestorePlugin } from 'vuefire'
import config from '../config'

const firebaseConfig = config.firebaseConfig()

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
}

Vue.use(firestorePlugin)

export default firebaseConfig
