<template>
  <v-btn
    icon
    class="action-close"
    large
    color="white"
    @click="$emit('input', false)"
  >
    <v-icon>mdi-close</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.action-close {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
