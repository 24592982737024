import { taxRef, parcelRef, parcelTypesRef, waterTestRef, eventRef, septicRef, hvacRef, solarRef } from '@/firebase/db'
import colors from '../design/_colors.scss'
import { mapGetters } from 'vuex'
import user from './user'
import address from './address'
import utils from './utils'
import config from '../config'
const api = config.api()
export default {
  mixins: [user, address, utils],
  data: () => ({
    tctParcels: [],
    totParcels: []
  }),
  mounted() {
    this.storeParcelTypes()
  },
  computed: {
    ...mapGetters(['viewMode']),
    parcels() {
      return this.copyObject(this.$store.getters.parcels)
    },
    teamFilter: {
      get() {
        return this.$store.getters.teamFilter
      },
      set(value) {
        this.$store.commit('setFilter', { key: 'team', value })
      }
    },
    acresFilter: {
      get() {
        return this.$store.getters.acresFilter
      },
      set(value) {
        this.$store.commit('setFilter', { key: 'acres', value })
      }
    },
    showParcelIdFilter: {
      get() {
        return this.$store.getters.showParcelIdFilter
      },
      set(value) {
        this.$store.commit('setFilter', { key: 'showParcelId', value })
      }
    },
    scaleFilter: {
      get() {
        return this.$store.getters.scaleFilter
      },
      set(value) {
        this.$store.commit('setFilter', { key: 'scale', value })
      }
    },
    referenceFilter: {
      get() {
        return this.$store.getters.filter.reference
      },
      set(value) {
        this.$store.commit('setFilter', { key: 'reference', value })
      }
    },
    tctFilter: {
      get() {
        return this.$store.getters.filter.tct
      },
      set(value) {
        this.$store.commit('setFilter', { key: 'tct', value })
      }
    },
    totFilter: {
      get() {
        return this.$store.getters.filter.tot
      },
      set(value) {
        this.$store.commit('setFilter', { key: 'tot', value })
      }
    },
    mapStyle: {
      get() {
        return this.$store.getters.mapStyle
      },
      set(style) {
        this.$store.commit('setMapStyle', style)
      }
    },
    loadingAddParcel: {
      get() {
        return this.$store.getters.loading.addParcel
      },
      set(value) {
        this.$store.commit('setLoading', { key: 'addParcel', value })
      }
    },
    parcelsComputed() {
      return this.parcels.filter(e => e.geojson || e.center)
    },
    parcelsFiltered() {
      let res = []

      if (this.viewMode == 'map') {
        res = this.copyObject(this.parcelsComputed)
      } else {
        res = this.copyObject(this.parcels)
      }

      const t = this.teamFilter
      if (t) {
        res = res.filter(parcel => {
          if (t == 'na') {
            return !parcel.team
          } else {
            return parcel.team == t
          }
        })
      }
      return res
    },
    teamsComputedMap() {
      let teams = []
      const ALL = { text: 'All', value: '' }
      const NA = { text: 'n/a', value: 'na' }

      let completed = {}

      let parcels
      if (this.viewMode == 'map') {
        parcels = this.copyObject(this.parcelsComputed)
      } else {
        parcels = this.copyObject(this.parcels)
      }

      parcels.forEach(p => {
        if (p.team && !teams.some(e => e.value == p.team)) {
          completed[p.team] = true
          teams.push({ text: p.team, value: p.team })
        }
      })

      teams.sort((a, b) => {
        return ('' + a.value).localeCompare(b.value)
      })

      teams.splice(0, 0, ALL)

      if (parcels.some(e => !e.team && e.center)) {
        teams.push(NA)
      }

      return teams
    },
    colors() {
      return colors
    }
  },
  methods: {
    checkTaxCount(parcel) {
      let res = false
      this.$store.getters.taxCount.forEach(e => {
        if (e.parcel == parcel) {
          return (res = true)
        }
      })
      return res
    },
    addLeadingZero(str) {
      if (str && str.indexOf('-') > -1) {
        let text = str.replace('.', '-').split('-')
        for (let index in text) {
          while (text[index].length < 3) {
            text[index] = '0' + text[index]
          }
        }

        // while (text.length < 3) {
        //   text.push("000");
        // }

        str = text.join('-')
      }
      return str
    },
    removePadding(parcel, addExtraZero) {
      parcel = parcel.split('-').map(e => Number(e))
      if (parcel.length < 3 && addExtraZero) {
        parcel.push('0')
      }
      parcel = parcel.join('-')
      return parcel
    },
    formatParcel(str, town, state) {
      let res = ``
      if (state) {
        res += `${state}_`
      }
      if (town) {
        res += `${town}_`
      }

      str = this.addLeadingZero(str)
      res += str

      return res
    },

    async getParcels(uid) {
      return new Promise((resolve, reject) => {
        parcelRef.where('user', '==', uid).onSnapshot(parcels => {
          let data = []
          parcels.forEach(p => {
            data.push({ ...p.data(), id: p.id })
          })
          this.$store.commit('setParcels', [...data])
          resolve()
        }, reject)
      })
    },
    async getParcelList(uid) {
      return parcelRef.where('user', '==', uid).get()
    },
    async getParcelDetail(id, set = false) {
      let res = await parcelRef.doc(id).get()
      if (res.exists) {
        let data = { ...res.data(), id: res.id }
        if (set) {
          this.$store.commit('latestParcel', data)
        }
        return data
      } else {
        return {}
      }
    },
    async addParcel(data) {
      this.$store.commit('latestParcel', null)
      let uid = await this.currentUser().uid
      this.$store.commit('parcelLoading', true)
      this.$store.commit('setParcels', [])
      let id = await parcelRef.add({ ...data, user: uid })
      let p = await this.getParcelDetail(id.id)
      this.$store.commit('latestParcel', p)
      this.$store.commit('parcelLoading', false)
      return id
    },
    async deleteParcel(id) {
      this.$store.commit('parcelLoading', true)

      await taxRef.doc(id).delete()
      await waterTestRef.doc(id).delete()
      await septicRef.doc(id).delete()
      await solarRef.doc(id).delete()
      await hvacRef.doc(id).delete()

      await parcelRef.doc(id).delete()

      this.$store.commit('parcelLoading', false)
    },
    async deleteAllParcels() {
      let parcels = await this.getParcelList(await this.currentUser().uid)
      parcels.forEach(async parcel => {
        await this.deleteParcel(parcel.id)
      })
    },
    parseParcelNumber(number, sep = '-') {
      if (!number) {
        return ''
      }
      let res = number.split('_')
      res.splice(0, 2)
      res = res[0]
      if (res && res.indexOf('-') > -1) {
        res = res.split('-')
        let i = res.length - 1
        while (res[i] === '000') {
          res.splice(-1, 1)
          i--
        }
        res = res.join(sep)
      }
      return res
    },
    async updateParcel(id, data) {
      await parcelRef.doc(id).set(data)
    },
    async getParcelTypes() {
      let res = await parcelTypesRef.doc('default').get()
      if (res.exists) {
        return res.data()
      } else {
        return { data: [] }
      }
    },
    async storeParcelTypes() {
      if (this.$store.getters.parcelTypes.data.length) {
        return
      }
      let res = await this.getParcelTypes()
      this.$store.commit('parcelTypes', res)
    },
    getPropertyCard(key) {
      return {
        url: `https://www.axisgis.com/TruroMA/Docs/Batch/Town%20Property%20Card/${key}.pdf`
      }
    },
    async addParcelAuthCertification(result) {
      let data = {
        user: await this.currentUser().uid,
        type: 'parcelAuthCertification',
        eventResult: result,
        timestamp: new Date()
      }
      eventRef.add(data)
    },
    hasURL(data, url) {
      let res = false
      if (data && typeof data === 'object') {
        if (data.url) {
          return (res = data.url == url)
        }
      }
      return res
    },
    async removeFileFromParcel(url, feature, parcel) {
      let ref = null
      if (feature == 'taxes') {
        ref = taxRef
      } else if (feature == 'water') {
        ref = waterTestRef
      } else if (feature == 'septic') {
        ref = septicRef
      } else if (feature == 'hvac') {
        ref = hvacRef
      } else {
        return
      }
      let doc = await ref.doc(parcel).get()
      if (doc.exists) {
        doc = doc.data()
        if (feature == 'septic') {
          doc = doc.septic_systems[0]
        }
        let fields = ['real_estate_bill', 'personal_property_bill', 'report', 'bill']
        doc.data.forEach((item, index) => {
          fields.forEach(field => {
            let tmp = item[field]
            if (this.hasURL(tmp, url)) {
              doc.data[index][field] = { exists: false }
            }
          })
        })
        ref.doc(parcel).set(doc)
      }
    },
    async getParcelsCount(uid) {
      let parcels = await this.getParcelList(uid)
      return parcels
    },

    async featuresCount(parcel_id) {
      let tax = await this.getTaxCount(parcel_id)
      tax = tax.data || 0
      let water = await this.getWaterTestCount(parcel_id)
      water = water.data || 0
      let septic = await this.getSepticCount(parcel_id)
      septic = septic.data || 0
      return { taxes: tax, water_tests: water, septic_systems: septic }
    },

    async isInTown(town) {
      if (!town) {
        return false
      }
      await this.setAddressData()
      let user = await this.currentUser()
      if (!user) {
        return false
      }
      let res = false
      await this.getParcels(user.uid)
      res = this.$store.getters.parcels.some(t => t.townCity == town)
      return res
    },
    getParcelTypeText(type) {
      let parcelTypes = this.$store.getters.parcelTypes
      let res = ''
      if (parcelTypes.data.length) {
        res = parcelTypes.data.filter(e => {
          return e.value == type
        })
        if (res.length) {
          res = res[0].text
        }
      }
      return res
    },

    getStreetAddress(parcel) {
      let res = ''
      if (!parcel) {
        return res
      }
      if (parcel.street_number) {
        res += `${parcel.street_number} `
      }
      if (parcel.street_name) {
        res += `${parcel.street_name}`
      }
      return res
    },
    toMapParcel(data) {
      if (data.indexOf('-') != -1) {
        data = data.split('-')
        if (data.length !== 4) {
          return data.join('-')
        }
        data.splice(1, 1)
        data.splice(2, 1)
        data = data.join('-')
      }
      return data
    },
    fromMapParcel(parcelId) {
      if (parcelId.indexOf('-') == -1) {
        return parcelId
      }
      parcelId = parcelId.split('-')
      parcelId.push('0')
      parcelId.splice(1, 0, '0')
      parcelId = parcelId.join('-')
      parcelId = this.addLeadingZero(parcelId)
      return parcelId
    },
    async getParcelInfo(parcelId) {
      if (!parcelId) {
        return
      }
      parcelId = this.fromMapParcel(parcelId)
      let parcelData
      try {
        parcelData = await this.$axios.get(`${api.truroAPI}/GetComputedDataApi?parcelId=${parcelId}`)
      } catch (err) {
        console.log(err)
        return
      }

      parcelData = parcelData.data
      if (!parcelData) {
        return
      }
      if (!parcelData.meta || typeof parcelData.meta !== 'object') {
        return
      }
      if (!parcelData.meta.status) {
        return
      }
      parcelData = parcelData.data
      if (!parcelData) {
        return
      }
      return parcelData
    },
    calculateSnl({ bedroom, acres, parcel_type }) {
      if (!bedroom || !acres) {
        return `Acres and Bedrooms must be specified first.`
      }
      if (isNaN(bedroom) || isNaN(acres)) {
        return ''
      }
      let snl = Number(bedroom) / Number(acres)
      if (parcel_type == 'resPartTime') {
        snl = snl * 0.5
      }
      return `${snl.toFixed(1)} bpa`
    },
    async parcelExists(parcel) {
      let res
      try {
        res = await this.$axios.get(`${api.cloudFunctionAPI}/parcel-exists?id=${parcel}`)
        if (res) {
          res = res.data
        }
      } catch (err) {
        console.log(err)
      }
      if (!res) {
        return
      }
      return res
    },
    getAcres(data) {
      let acres = ''
      try {
        acres = (Number(data.geojson.properties.SUM_LOT_SIZE) / 44000).toFixed(1)
      } catch (err) {
        console.log(err)
      }
      return acres
    },
    async getNearbyParcels(parcels) {
      const _this = this
      let nearbyParcels = []

      parcels.forEach(async parcel => {
        const parcelId = this.fromMapParcel(parcel.number)
        nearbyParcels.push(this.$axios.get(`${api.truroAPI}/GetNeighbuttersDataApi?parcelId=${parcelId}&owner=*&geojson=1`))
      })

      let parcelResults = await Promise.all(nearbyParcels.map(p => p.catch(e => e)))
      parcelResults = parcelResults.filter(parcel => !(parcel instanceof Error)).map(parcel => parcel.data)

      let tctParcels = []
      let totParcels = []

      parcelResults.map(parcel => {
        let data = { ...parcel }

        if (!data.meta || !data.meta.status) {
          return
        }

        data = data.data
        if (data.tct) {
          Object.keys(data.tct).forEach(parcel => {
            const parcelData = {
              ...data.tct[parcel],
              number: _this.toMapParcel(parcel),
              owner: 'tct',
              index: Math.floor(Math.random() * 100) + 100,
              acres: _this.getAcres(data.tct[parcel])
            }
            tctParcels.push(parcelData)
          })
        }

        if (data.tot) {
          Object.keys(data.tot).forEach(parcel => {
            const parcelData = {
              ...data.tot[parcel],
              number: _this.toMapParcel(parcel),
              owner: 'tot',
              index: Math.floor(Math.random() * 100) + 100,
              acres: _this.getAcres(data.tot[parcel])
            }
            totParcels.push(parcelData)
          })
        }
      })

      tctParcels = tctParcels.filter((v, i, a) => a.findIndex(t => t.number === v.number) === i)
      totParcels = totParcels.filter((v, i, a) => a.findIndex(t => t.number === v.number) === i)

      this.tctParcels = tctParcels
      this.totParcels = totParcels
    },
    mapLayerColor(owner) {
      if (owner == 'tct') {
        return '#00BCD4'
      }
      if (owner == 'tot') {
        return '#FFEB3B'
      }
      if (this.mapStyle == this.MAP_CONFIG.styles.satellite || this.mapStyle == this.MAP_CONFIG.styles.satelliteStreet) {
        return 'white'
      }
      return 'light-black'
    },
    async updateParcelGeoData(id) {
      let data = await parcelRef.doc(id).get()
      if (!data.exists) {
        return
      }
      data = { ...(await data.data()) }

      let parcelId = this.fromMapParcel(data.number)

      let url = `GetComputedDataApi?parcelId=${parcelId}`

      let res
      try {
        res = await this.$axios.get(`${api.truroAPI}/${url}`)
      } catch (err) {
        console.log(err)
      }
      if (!res || !res.data) {
        return
      }
      res = res.data
      if (!res.meta.status) {
        console.warn(`Geodata not found: ${JSON.stringify(res)}`)
        return
      }

      let parcelData = this.copyObject(data)
      parcelData.timestampGeoUpdated = new Date()

      let geoData = {
        center: res.data.center,
        centroid: res.data.centroid,
        geojson: res.data.geojson,
        bbox: res.data.bbox
      }

      if (!geoData.geojson) {
        console.warn(`Geojson not found. Parcel number: ${data.number},  ID: ${id}`)
      }
      if (!geoData.center) {
        console.warn(`Center not found. Parcel number: ${data.number},  ID: ${id}`)
      }
      if (!geoData.centroid) {
        console.warn(`Centroid not found. Parcel number: ${data.number},  ID: ${id}`)
      }

      if (geoData.geojson) {
        geoData.geojson = JSON.stringify(geoData.geojson)
      }

      parcelData = { ...parcelData, ...geoData }
      this.updateParcel(id, parcelData)
    }
  }
}
