<template>
  <section>
    <v-app-bar app flat>
      <v-img
        @click="$router.push('/')"
        class="ml-4 mr-4 peeps-image cursor-pointer"
        src="@/assets/img/PeepsBeside.svg"
      ></v-img>
      <v-toolbar-title
        class="peeps--text peeps--font toolbar-title cursor-pointer"
        @click="$router.push('/')"
        >My Peeps Portal</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        text
        to="/login"
        v-if="!$store.getters.auth"
        class="d-md-none d-flex"
        >Login</v-btn
      >
      <div class="d-md-none d-flex" v-if="$store.getters.auth">
        <screen-size></screen-size>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" color="primary" icon @click="logout()"
              ><v-icon>mdi-logout</v-icon></v-btn
            >
          </template>
          <span>Logout</span>
        </v-tooltip>
      </div>
      <div class="d-md-none d-flex flex-column">
        <v-app-bar-nav-icon
          class="menu-icon"
          @click.stop="drawer = !drawer"
        ></v-app-bar-nav-icon>
        <span class="menu-text">Menu</span>
      </div>
      <div class="d-md-flex d-none">
        <v-btn
          text
          v-for="(nav, index) in nav_links"
          :key="index"
          :to="nav.to"
          color="primary"
          >{{ nav.text }}</v-btn
        >
        <screen-size></screen-size>
        <v-btn text color="primary" to="/login" v-if="!$store.getters.auth"
          >Login</v-btn
        >
        <!-- <v-btn text color="primary" to="/signup" v-if="!$store.getters.auth"
          >Sign Up</v-btn
        > -->
        <v-btn
          v-if="$store.getters.auth"
          @click="logout()"
          text
          class="text-capitalize ml-1"
          color="primary"
        >
          <span v-if="$vuetify.breakpoint.lgAndUp"> Logout</span>
          <v-icon>mdi-logout</v-icon>
        </v-btn>
        <v-btn
          text
          color="primary"
          to="/profile"
          v-if="$store.getters.auth && user_profile"
          class="text-capitalize"
        >
          <span class="d-none d-md-inline mr-1">
            {{ user_profile.first_name }}
          </span>
          <span class="avatar">
            {{ user_profile.first_name.substring(0, 1) }}</span
          >
        </v-btn>
        <v-btn v-if="$store.getters.auth && !user_profile" text>
          <v-progress-circular
            color="default"
            indeterminate
            size="20"
            width="3"
          ></v-progress-circular>
        </v-btn>
      </div>
    </v-app-bar>
    <v-navigation-drawer right app disable-resize-watcher v-model="drawer">
      <v-list nav>
        <v-list-item class="px-2">
          <v-img src="@/assets/img/PeepsBeside.svg" max-width="50"></v-img>
          <v-toolbar-title
            class="peeps--text peeps--font"
            @click="$router.push('/')"
            >My Peeps Portal</v-toolbar-title
          >
        </v-list-item>
        <v-list-item-group color="primary">
          <v-list-item
            v-for="(nav, index) in nav_links"
            :key="index"
            :to="nav.to"
          >
            <v-list-item-title>{{ nav.text }}</v-list-item-title>
          </v-list-item>

          <v-list-item to="/profile" v-if="$store.getters.auth">
            <v-list-item-title>
              <span v-if="user_profile" class="text-capitalize">
                <span class="mr-1">
                  {{ user_profile.first_name }}
                </span>
                <span class="avatar">
                  {{ user_profile.first_name.substring(0, 1) }}</span
                >
              </span>

              <span class="text-capitalize" v-else
                ><v-progress-circular
                  color="default"
                  indeterminate
                  size="20"
                  width="3"
                ></v-progress-circular
              ></span>
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </section>
</template>

<script>
import user from '@/mixins/user'
export default {
  data: () => ({
    nav_links: [
      { text: 'Home', to: '/' },
      { text: 'Features', to: '/features' },
      { text: 'Parcels', to: '/parcels' },
      { text: 'About', to: '/about' },
      { text: 'Help', to: '/help' }
    ],
    drawer: false
  }),
  mixins: [user],
  computed: {
    user_profile() {
      if (this.$store.getters.user.profile.first_name) {
        return this.$store.getters.user.profile
      }
      return null
    },
    md() {
      return this.$vuetify.breakpoint.md
    }
  },
  watch: {
    md() {
      if (this.md) {
        this.drawer = false
      }
    }
  }
}
</script>

<style></style>
