<template>
  <base-feature-button
    width="150"
    large
    color="cyan"
    dark
    to="/about/privacy"
    class="text-capitalize"
  >
    <template v-slot:text>Privacy</template>
    <template v-slot:suffix>
      <v-icon> mdi-shield-account </v-icon>
    </template></base-feature-button
  >
</template>

<script>
import BaseFeatureButton from './BaseFeatureButton'
export default {
  components: {
    BaseFeatureButton
  }
}
</script>

<style></style>
