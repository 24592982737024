<template>
  <div class="d-flex flex-wrap justify-center">
    <div
      v-for="item in items"
      :key="item.id"
      class="img-thumbnail d-flex"
      @mouseenter="onMouseEnter"
    >
      <v-hover v-slot="{ hover }">
        <div class="base-image-container">
          <base-image
            class="base-image cursor-pointer"
            cover
            max-width="200"
            :src="item.thumbnail || item.files[0].url"
            @click="$emit('showDetail', item)"
          >
            <div v-show="item.private" class="label-private opacity-75">
              <v-icon color="white" size="13">mdi-lock</v-icon>
            </div>
            <div
              v-show="item.files.length > 1"
              class="images-count-container opacity-75"
            >
              <span class="images-count cursor-pointer">
                <v-icon size="13" color="white">mdi-image-multiple</v-icon>
              </span>
            </div>
            <v-fade-transition>
              <div v-show="showDateShared" class="date-shared opacity-75">
                {{ formatDate(item.createdAt.toDate(), 'YYYY-MM-DD') }}
              </div>
            </v-fade-transition>
            <v-slide-x-transition>
              <div
                v-show="showViews && item.views"
                class="views-count-container opacity-75"
              >
                <span class="views-count">
                  {{ item.views }}
                </span>
              </div>
            </v-slide-x-transition>
          </base-image>
          <v-fade-transition v-if="hover">
            <div class="image-detail">
              <gallery-detail-hover
                :galleryCommentRef="galleryCommentRef"
                :galleryRef="galleryRef"
                :show-date-shared="!showDateShared"
                :id="item.id"
              ></gallery-detail-hover>
            </div>
          </v-fade-transition>
        </div>
      </v-hover>
    </div>
  </div>
</template>

<script>
import Moment from 'moment'
import GalleryDetailHover from './DetailHover.vue'
export default {
  components: {
    GalleryDetailHover
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    showViews: {
      type: Boolean,
      default: false
    },
    showDateShared: {
      type: Boolean,
      default: false
    },
    galleryCommentRef: {
      type: Object,
      required: true
    },
    galleryRef: {
      type: Object,
      required: true
    }
  },
  methods: {
    hasTouch() {
      return !!('ontouchstart' in window)
    },
    formatDate(date, fmt = 'std') {
      if (!date) {
        return ''
      }
      if (fmt === 'std') {
        fmt = 'YYYY-MM-DD h:m:s A'
      }
      if (!date || date === 'Invalid Date') {
        return ''
      }
      return Moment(date).format(fmt)
    },
    onMouseEnter(e) {
      if (this.hasTouch()) {
        return
      }
      setTimeout(() => {
        try {
          const elemImage = e.target.firstChild.children[0]
          const elemDetalil = e.target.firstChild.children[1]

          const top = `${elemImage.offsetHeight}px`
          elemDetalil.style.top = top

          elemDetalil.style.display = 'block'
        } catch (error) {
          console.warn(error)
        }
      }, 5)
    }
  }
}
</script>

<style scoped lang="scss">
$bg-grey: #54606d;
.base-image-container {
  position: relative;
}

$w1: 90px;
$w2: 90px;
$w3: 90px;
$w4: 90px;
$w5: 90px;
$w6: 90px;

$margin-between: 3px;

.base-image {
  width: $w1;
  height: $w1;
  @media (min-width: 960px) and (max-width: 1264px) {
    height: $w2;
    width: $w2;
  }

  @media (min-width: 600px) and (max-width: 960px) {
    height: $w3;
    width: $w3;
  }
  @media (max-width: 600px) {
    height: $w4;
    width: $w4;
  }
  @media (max-width: 485px) {
    height: $w5;
    width: $w5;
  }
  @media (max-width: 400px) {
    height: $w6;
    width: $w6;
  }

  margin-left: $margin-between;
  margin-bottom: $margin-between;
}

.label-private {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 14px;

  @media (max-width: 600px) {
    font-size: 10px;
  }

  background-color: $bg-grey;
  padding-right: 2px;
  padding-left: 2px;
  color: #e4eaf0;
  border-bottom-right-radius: 7px;
}
.images-count-container {
  background-color: $bg-grey;
  position: absolute;
  top: 0;
  right: 0;
  border-bottom-left-radius: 7px;
  margin-top: 0px !important;

  padding-left: 3px;
  padding-right: 3px;
}

.views-count-container {
  // height: 20px;
  // min-width: 20px;
  padding-right: 2px;
  padding-left: 2px;
  background-color: $bg-grey;
  position: absolute;
  bottom: 0;
  border-top-right-radius: 7px;
  .views-count {
    display: flex;
    padding: 1px 3px 1px 1px;
    height: 100%;
    font-size: 14px;

    @media (max-width: 600px) {
      font-size: 10px;
    }

    justify-content: center;
    align-content: center;
    color: #d5e4f3;
  }
}
.date-shared {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: -2px;
  font-size: 10px;

  @media (max-width: 600px) {
    font-size: 8px;
  }

  background-color: $bg-grey;
  padding-left: 5px;
  color: #e4eaf0;
  border-top-left-radius: 7px;
}

.image-detail {
  position: absolute;
  bottom: 0;
  z-index: 3;
  display: none;
  margin-left: 3px;
}
</style>
