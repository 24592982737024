// import {userRef} from '../services/firestore'
import firebase from 'firebase/app'
import 'firebase/auth'
import { firestore, COLLECTION } from '../services/firestore'

class User {
  constructor() {
    this.ref = firestore.collection(COLLECTION.user)
  }

  currentUser() {
    return firebase.auth().currentUser
  }

  getProfile(id) {
    return this.ref.doc(id).get()
  }

  async updateProfile(obj) {
    const user = this.currentUser()
    if (!user) {
      return
    }
    const uid = user.uid
    const profile = await this.ref.doc(uid).get()
    const data = { ...profile.data() }
    const newData = { ...data, ...obj }

    return await this.ref.doc(uid).set(newData)
  }
}

export default User
