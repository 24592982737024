<template>
  <div ref="swiper" class="swiper-container">
    <div class="swiper-wrapper">
      <slot></slot>
    </div>
    <div class="divider"></div>
    <slot name="navigation">
      <div v-show="navigation">
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>
    </slot>
    <slot name="pagination">
      <div v-show="pagination" class="swiper-pagination"></div>
    </slot>
  </div>
</template>

<script>
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper'
import 'swiper/swiper-bundle.css'
Swiper.use(Navigation)
Swiper.use(Pagination)
Swiper.use(Autoplay)

export default {
  props: {
    navigation: {
      type: Boolean,
      default: false
    },
    pagination: {
      type: Boolean,
      default: false
    },
    swiperConfig: {
      type: Object
    },
    value: { type: Number, default: 0 }
  },

  data() {
    return {
      swiper: null,
      defaultConfig: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  watch: {
    value: {
      handler: 'updateSlide',
      immediate: true
    }
  },
  mounted() {
    setTimeout(() => {
      this.init()
    }, 100)
  },
  methods: {
    init() {
      const _this = this
      this.swiper = new Swiper(this.$refs.swiper, {
        ...this.defaultConfig,
        ...this.swiperConfig,
        on: {
          init: self => {
            _this.updateSlide(self)
          }
        }
      })
      this.swiper.on('transitionEnd', function () {
        _this.$emit('input', _this.swiper.realIndex)
      })
      this.swiper.on('onSlideChangeStart', function () {
        _this.updateSlide()
      })
    },
    updateSlide(swiper) {
      let s
      if (typeof swiper === 'object') {
        s = swiper
      }
      if (!s) {
        s = this.swiper
      }
      if (!s) {
        return
      }
      if (s.realIndex === this.value) {
        return
      }
      s.slideTo(this.value)
    }
  }
}
</script>

<style lang="scss">
.swiper-container {
  width: 100%;
  height: 100%;
}
.divider {
  padding-top: 10px;
}
</style>
