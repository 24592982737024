import Vue from 'vue'

import './Icons'

const requireComponent = require.context('.', false, /\.vue/)
requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)
  const componentName = `Base${fileName
    .replace(/\.\//, '')
    .replace(/\.vue$/, '')}`
  Vue.component(componentName, componentConfig.default || componentConfig)
})
