<template>
  <transition
    :enter-active-class="`animate__animated ${enter}`"
    :leave-active-class="`animate__animated ${exit}`"
  >
    <div v-if="show">
      <slot>
        {{ message }}
      </slot>
    </div>
  </transition>
</template>
<script>
export default {
  name: 'BaseAnimation',

  props: {
    enter: {
      type: String,
      default: 'animate__fadeIn'
    },
    exit: {
      type: String,
      default: 'animate__fadeOut'
    },
    timeout: {
      type: Number,
      default: 3000
    },
    value: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    show: false
  }),

  beforeMount() {
    this.show = this.value
  },

  computed: {
    getTimeout() {
      return this.timeout
    }
  },

  methods: {
    emit() {
      this.$emit('input', this.show)
    },

    animateEnter() {
      this.show = true
    },

    async animateExit() {
      this.show = false
    },

    animate() {
      this.animateEnter()
      setTimeout(() => {
        this.animateExit()
      }, this.getTimeout)
    }
  },
  watch: {
    show: {
      handler: 'emit'
    },
    value() {
      this.show = this.value
    }
  }
}
</script>

<style></style>
