<template>
  <div class="swiper-slide">
    <slot></slot>
  </div>
</template>

<script>
export default {}
</script>

<style lang="sass">
.swiper-slide
  text-align: center
  display: -webkit-box
  display: -ms-flexbox
  display: -webkit-flex
  display: flex
  -webkit-box-pack: center
  -ms-flex-pack: center
  -webkit-justify-content: center
  justify-content: center
  -webkit-box-align: center
  -ms-flex-align: center
  -webkit-align-items: center
  align-items: center
  img
    width: 100%
    display: block
    width: 100%
    height: 100%
    object-fit: cover

.swiper-slide
  width: 80%

.swiper-slide:nth-child(2n)
  width: 60%

.swiper-slide:nth-child(3n)
  width: 40%
</style>
