<template>
  <div>
    <GalleryUpdateForm
      :data="data"
      update
      :photo-required="false"
      :user-setting="userSetting"
      :categories="categories"
      :show-file-upload="false"
      :logged-in="loggedIn"
      :gallery-ref="galleryRef"
      :admin="admin"
      :show-footer="false"
      @cancel="$emit('cancel')"
      @success="$emit('success')"
      :fields="fields"
    >
      <template v-if="data && data.files && showImages" #footer>
        <div class="d-flex flex-wrap">
          <v-img
            v-for="(img, index) in data.files"
            :key="index"
            :src="img.url"
            class="mx-2 my-2"
            max-width="200px"
          ></v-img>
        </div>
      </template>
    </GalleryUpdateForm>
  </div>
</template>

<script>
import { storage } from '@/services/storage'
import { galleryRef } from '@/services/firestore'
import GalleryUpdateForm from './GalleryUpdateForm.vue'
import config from '../../config'
export default {
  components: {
    GalleryUpdateForm
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    showImages: {
      type: Boolean,
      default: true
    },
    admin: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      data: {},
      error: null,
      loggedIn: true,
      categories: [],
      userSetting: {},

      storage,
      dialogs: { share: false },
      galleryRef,
      userData: {
        email: '',
        firstName: '',
        lastName: ''
      },
      selectedCategory: 'Parcel',
      gallery: {
        name: 'Peeps',
        id: config.galleryID()
      },
      fields: ['caption', 'subcaption', 'imageUpload', 'parcel']
    }
  },
  watch: {
    id: {
      handler: 'getData',
      immediate: true
    }
  },
  computed: {
    // ...mapGetters({
    //   loggedIn: 'auth/loggedIn',
    //   categories: 'gallery/categories',
    //   userSetting: 'user/setting',
    //   gallery: 'gallery/gallery',
    //   user: 'user/user'
    // })
  },
  methods: {
    async getData() {
      if (!this.id) {
        return
      }
      let res
      try {
        res = await this.galleryRef.doc(this.id).get(0)
      } catch (err) {
        this.error = err
        console.error(err)
      }
      if (res.exists) {
        this.data = { ...res.data(), id: res.id }
      }
    }
  }
}
</script>

<style></style>
