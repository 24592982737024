import firebase from 'firebase/app'
import 'firebase/storage'
import './firebase'

const STORAGE = {
  gallery: 'gallery'
}

const storage = firebase.storage()

const uploadFile = async (file, path) => {
  const ref = storage.ref().child(path)
  await ref.put(file)
}

const getDownloadURL = async path => {
  return await storage
    .ref()
    .child(path)
    .getDownloadURL()
}

const getThumbnailURL = async (filename, thumb) => {
  return await getDownloadURL(`thumbnails${thumb}/thumb@${thumb}_${filename}`)
}

export {
  uploadFile,
  STORAGE,
  getDownloadURL,
  getThumbnailURL,
  storage
}
