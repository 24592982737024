<script>
import BaseAction from './BaseAction.vue'
export default {
  extends: BaseAction,
  props: {
    icon: {
      type: String,
      default: 'mdi-trash-can'
    },
    color: {
      type: String,
      default: 'error'
    }
  }
}
</script>

<style></style>
