import { addressRef } from '@/firebase/db'
export default {
  data: () => ({
    addressList: [
      { zip: '02666', state: 'MA', townCity: 'Truro' },
      { zip: '02652', state: 'MA', townCity: 'Truro' },
      { zip: '02667', state: 'MA', townCity: 'Wellfleet' },
      { zip: '02663', state: 'MA', townCity: 'Wellfleet' },
      { zip: '02445', state: 'MA', townCity: 'Brookline' },
      { zip: '02446', state: 'MA', townCity: 'Brookline' },
      { zip: '02474', state: 'MA', townCity: 'Arlington' },
      { zip: '02493', state: 'MA', townCity: 'Weston' },
      { zip: '02657', state: 'MA', townCity: 'Provincetown' },
      { zip: '02651', state: 'MA', townCity: 'Eastham' },
      { zip: '02642', state: 'MA', townCity: 'Eastham' },
      { zip: '02653', state: 'MA', townCity: 'Orleans' },
      { zip: '02631', state: 'MA', townCity: 'Brewster' },
      { zip: '02061', state: 'MA', townCity: 'Norwell' },
      { zip: '02066', state: 'MA', townCity: 'Scituate' },
      { zip: '02116', state: 'MA', townCity: 'Boston' },
      { zip: '01845', state: 'MA', townCity: 'North Andover' }
    ]
  }),
  async mounted() {
    await this.setAddressData()
  },
  methods: {
    async getAddress() {
      let res = await addressRef.doc('states').get()
      let states = []
      let townsCities = []

      res.data().data.forEach(state => {
        states.push(state.text)
        state.townsCities.forEach(town => {
          townsCities.push(town.text)
        })
      })
      return {
        states: states,
        townsCities: townsCities,
        addresses: res.data().data
      }
    },
    async setAddressData() {
      if (!this.$store.getters.addresses.length) {
        let addresses = await this.getAddress()
        this.$store.commit('addresses', addresses.addresses)
        if (addresses.townsCities) {
          this.$store.commit('townsCities', addresses.townsCities)
        }
        if (addresses.states) {
          this.$store.commit('states', addresses.states)
        }
      }
    },
    addressForZip(zip) {
      zip = String(zip).substring(0, 5)
      let search = this.addressList.filter(e => String(e.zip) === zip)
      if (search.length) {
        return search[0]
      } else {
        return null
      }
    },
    async getHealthDepartment(state, town) {
      await this.setAddressData()
      let url = ''
      let filter = this.$store.getters.addresses.filter(e => e.text == state)
      // check state
      filter = filter[0]
      if (filter) {
        filter = filter.townsCities.filter(e => e.text == town)
      }
      // check town city
      filter = filter[0]
      if (filter) {
        url = filter.health_department
      }
      return url
    }
  }
}
