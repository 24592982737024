<template>
  <v-card width="300" v-bind="$attrs">
    <v-card-title v-show="showTitle">
      <slot name="title">
        Filter By
      </slot>
    </v-card-title>
    <v-card-text>
      <span
        class="ml-2 cursor-pointer"
        v-for="(item, index) in items"
        :key="index"
        @click="emit(value == item[itemValue] ? '' : item[itemValue])"
      >
        <v-chip
          class="mb-2"
          :dark="itemStyle ? isDark(item) : false"
          :color="getChipColor(item)"
          >{{ item[itemText] }}</v-chip
        >
      </span>
      <div
        v-if="value != clearValue && showClearButton"
        class="text-center mt-2"
      >
        <span @click="emit(clearValue)">
          <cancel-button rounded
            >Clear Filter <v-icon>mdi-close</v-icon></cancel-button
          >
        </span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    value: {
      type: String
    },
    items: {
      type: Array
    },
    clearValue: {
      type: String,
      default: ''
    },
    itemText: {
      type: String,
      default: 'text'
    },
    itemValue: {
      type: String,
      default: 'value'
    },
    activeColor: {
      type: String,
      default: 'primary'
    },
    defaultColor: {
      type: String,
      default: 'secondary'
    },
    itemStyle: {
      type: Boolean,
      default: false
    },
    showClearButton: {
      type: Boolean,
      default: true
    },
    showTitle: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    emit(val) {
      this.$emit('input', val)
    },
    isDark(item) {
      return item.style.dark
    },
    getChipColor(item) {
      let color = this.defaultColor
      if (this.itemStyle) {
        color = item.style.color
      } else {
        if (item[this.itemValue] == this.value) {
          color = this.activeColor
        }
      }
      return color
    }
  }
}
</script>

<style></style>
