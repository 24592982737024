import { DEFAULT_MAP_STYLE } from '../../mixins/map'
import User from '../../services/user'

const user = new User();

export default {
  state: {
    parcels: [],
    taxCount: [],
    waterTestCount: [],
    parcelTypes: { data: [] },
    parcelLoading: true,
    disableCreateParcel: false,
    latestParcel: null,
    viewMode: '',
    filterTeam: '',
    map: null,
    mapConfig: {
      team: '',
      acres: true,
      scale: true,
      reference: false,
      tct: false,
      tot: false,
      hash: '',
      style: DEFAULT_MAP_STYLE,
      showParcelId: true
    },
    gallerySetting: {
      showDate: false,
    },
    loading: {
      addParcel: false
    }
  },
  getters: {
    parcels(state) {
      state.parcels.map((e, i) => {
        e.index = i
        return e
      })
      return state.parcels
    },
    taxCount(state) {
      return state.taxCount
    },
    waterTestCount(state) {
      return state.waterTestCount
    },
    parcelTypes(state) {
      return state.parcelTypes
    },
    parcelLoading(state) {
      return state.parcelLoading
    },
    latestParcel(state) {
      return state.latestParcel
    },
    viewMode(state) {
      return state.viewMode
    },
    filterTeam(state) {
      return state.filterTeam
    },
    map(state) {
      return state.map
    },

    loading(state) {
      return state.loading
    },
    disableCreateParcel(state) {
      return state.disableCreateParcel
    },
    filter(state) {
      return state.mapConfig
    },
    teamFilter(state) {
      return state.mapConfig.team
    },
    acresFilter(state) {
      return state.mapConfig.acres
    },
    showParcelIdFilter(state) {
      return state.mapConfig.showParcelId
    },
    scaleFilter(state) {
      return state.mapConfig.scale
    },
    mapStyle(state) {
      return state.mapConfig.style
    },
    mapConfig(state) {
      return state.mapConfig
    },
    gallerySetting(state) {
      return state.gallerySetting;
    },
  },
  mutations: {
    setParcels(state, data) {
      state.parcel = []
      state.parcels = data
    },
    setTaxCount(state, data) {
      let found = false
      state.taxCount.forEach((e, index) => {
        if (e.parcel == data.parcel) {
          state.taxCount.splice(index, 1, e)
        }
      })
      if (!found) {
        state.taxCount.push(data)
      }
    },
    setWaterTestCount(state, data) {
      let found = false
      state.waterTestCount.forEach((e, index) => {
        if (e.parcel == data.parcel) {
          state.waterTestCount.splice(index, 1, e)
        }
      })
      if (!found) {
        state.waterTestCount.push(data)
      }
    },
    parcelTypes(state, data) {
      state.parcelTypes = data
    },
    parcelLoading(state, data) {
      state.parcelLoading = data
    },
    latestParcel(state, data) {
      state.latestParcel = data
    },
    viewMode(state, data) {
      state.viewMode = data
    },
    setLoading(state, { key, value }) {
      state.loading[key] = value
    },
    setDisableCreateParcel(state, val) {
      state.disableCreateParcel = val
    },
    setMap(state, map) {
      state.map = map
    },
    setMapStyle(state, style) {
      state.mapConfig.style = style
    },
    setFilter(state, { key, value }) {
      state.mapConfig[key] = value
    },
    setMapConfigObj(state, config) {
      state.mapConfig = { ...state.mapConfig, ...config }
    },
    setMapConfig(state, { key, value }) {
      state.mapConfig[key] = value

      localStorage.setItem('mapConfig', JSON.stringify(state.mapConfig))
    },
    updateGallerySettingObj(state, obj) {
      state.gallerySetting = obj;
    },
    updateGallerySetting(state, { key, value }) {
      state.gallerySetting[key] = value;
    }
  },
  actions: {
    updateGallerySettingObj({ commit }, obj) {
      commit("updateGallerySettingObj", obj)
    },
    updateGallerySetting({ commit, state }, data) {
      commit("updateGallerySetting", data)
      user.updateProfile({ peepsGallerySetting: state.gallerySetting })
    }
  }
}
