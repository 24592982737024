import user from '@/mixins/user'
import utils from '@/mixins/utils'
import map from '../mixins/map'

export default {
  install(Vue) {
    Vue.mixin(user)
    Vue.mixin(utils)
    Vue.mixin(map)
  }
}
