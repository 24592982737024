<template>
  <base-feature-button
    width="150"
    large
    color="primary"
    to="/about/peeps"
    class="text-capitalize"
  >
    <template v-slot:text>Peeps</template>
    <template v-slot:suffix>
      <img src="@/assets/img/favicon.png" width="30" class="ml-1" /> </template
  ></base-feature-button>
</template>

<script>
import BaseFeatureButton from './BaseFeatureButton'
export default {
  components: {
    BaseFeatureButton
  }
}
</script>

<style></style>
