/* eslint-disable no-unused-vars */
import * as FilePond from 'filepond'
import 'filepond/dist/filepond.min.css'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'

import {
  getImageId
} from '@/services/gallery'

FilePond.registerPlugin(FilePondPluginFileValidateSize)
FilePond.registerPlugin(FilePondPluginImagePreview)
FilePond.registerPlugin(FilePondPluginFileValidateType)

const createFilePond = (el, onProcess, onRemove) => {
  const inputElement = document.querySelector(el)
  return FilePond.create(inputElement, {
    allowFileSizeValidation: true,
    maxFileSize: '20MB',
    allowFileTypeValidation: true,
    acceptedFileTypes: ['image/*', 'image/heic'],
    labelIdle: `
        Drag &amp; Drop your images or <span class="filepond--label-action" tabindex="0">Browse</span>`,

    server: {
      process: async (
        fieldName,
        file,
        metadata,
        load,
        error,
        progress,
        abort,
        transfer,
        options
      ) => {
        metadata.foo = 'bar'
        try {
          const id = await getImageId()
          if (!id) {
            throw new Error('Error generating Image ID')
          }
          file.id = id
          await onProcess(file)
          load()
        } catch (err) {
          error()
        }
      }
    },
    onremovefile: (_error, file) => {
      onRemove(file.file.id)
    }
  })
}

export {
  createFilePond
}
