<template>
  <v-expand-transition>
    <v-card class="mx-auto my-1" elevation="3" v-show="value">
      <v-container v-if="latestParcel">
        <ParcelGalleryDetail
          :parcel="latestParcel.number"
          :feature="feature"
          @update:count="onUpdateCount"
        />
      </v-container>
    </v-card>
  </v-expand-transition>
</template>

<script>
import ParcelGalleryDetail from './ParcelGalleryDetail.vue'
import { mapGetters } from 'vuex'
export default {
  props: {
    feature: {
      type: String,
      default: 'Parcel'
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ParcelGalleryDetail
  },
  computed: {
    ...mapGetters(['latestParcel'])
  },
  methods: {
    onUpdateCount(value) {
      this.$emit('update:count', value)
    }
  }
}
</script>

<style></style>
