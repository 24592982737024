<template>
  <v-icon class="cursor-pointer" :color="color" @click="onClick">{{
    icon
  }}</v-icon>
</template>

<script>
export default {
  props: {
    icon: {
      type: String
    },
    color: {
      type: String
    }
  },
  methods: {
    onClick() {
      this.$emit('click')
    }
  }
}
</script>

<style></style>
