export default {
  appName: 'Peeps Portal',
  deployment: process.env.VUE_APP_DEPLOYMENT,
  productionBranches: ['main', 'beta'],
  developmentBranches: ['dev'],

  baseURL: 'https://www.peepsportal.org',
  emailDomain: 'peepsportal.org',
  mapboxToken: 'pk.eyJ1IjoiY2xhc3NpY25ldXBhbmUiLCJhIjoiY2tubmJuNm1iMHhrNDJwcGZsNXM0bXgxcyJ9.eJfcXAnt_ukVCkrXFduZXw',

  galleryIDs: {
    development: '9aD4Z140CFl7RhaS8N4X',
    production: 'sSj9yMXar8yXXYr9svFv'
  },

  apiURL: {
    production: {
      cloudFunction: 'https://us-central1-property-analysis-29b2f.cloudfunctions.net/',
      cloudFunctionAPI: 'https://us-central1-property-analysis-29b2f.cloudfunctions.net/api',
      solar: 'https://us-central1-test-truro.cloudfunctions.net/FirebaseJsonApi?path=MA/Truro/solar',
      truroAPI: 'https://us-central1-test-truro.cloudfunctions.net'
    },
    development: {
      cloudFunction: 'https://us-central1-freelancer-brian-b.cloudfunctions.net',
      cloudFunctionAPI: 'https://us-central1-freelancer-brian-b.cloudfunctions.net/api',
      solar: 'https://us-central1-test-truro.cloudfunctions.net/FirebaseJsonApi?path=MA/Truro/solar',
      truroAPI: 'https://us-central1-test-truro.cloudfunctions.net'
    }
  },

  firebase: {
    production: {
      apiKey: 'AIzaSyCWTg3cDv3zhqo2QxtDi6wZsrv_YKWh6Sg',
      authDomain: 'property-analysis-29b2f.firebaseapp.com',
      databaseURL: 'https://property-analysis-29b2f-default-rtdb.firebaseio.com',
      projectId: 'property-analysis-29b2f',
      storageBucket: 'property-analysis-29b2f.appspot.com',
      messagingSenderId: '482032857621',
      appId: '1:482032857621:web:dece6b892abaa70114d41c',
      measurementId: 'G-6RZ7D9MGJ1'
    },
    development: {
      apiKey: 'AIzaSyC3AIhc1wG-ofnt6s70BcidAXdnNRV5PzE',
      authDomain: 'freelancer-brian-b.firebaseapp.com',
      databaseURL: 'https://freelancer-brian-b-default-rtdb.firebaseio.com',
      projectId: 'freelancer-brian-b',
      storageBucket: 'freelancer-brian-b.appspot.com',
      messagingSenderId: '149556519970',
      appId: '1:149556519970:web:18c6bc034bd61c9049167b'
    }
  },

  firestore: {
    folder: 'folders',
    file: 'files'
  },

  storage: {
    driveBucketDev: '',
    driveBucket: ''
  },
  driveFolderName: 'drive',

  isProduction() {
    return this.productionBranches.includes(this.deployment)
  },

  storageDriveBucket() {
    if (this.productionBranches.includes(this.deployment)) {
      return this.storage.driveBucket
    }
    if (this.developmentBranches.includes(this.deployment)) {
      return this.storage.driveBucketDev
    }
    return ''
  },

  firebaseConfig() {
    if (this.isProduction()) {
      return this.firebase.production
    } else {
      return this.firebase.development
    }
  },

  api() {
    if (this.isProduction()) {
      return this.apiURL.production
    } else {
      return this.apiURL.development
    }
  },

  galleryID() {
    if (this.isProduction()) {
      return this.galleryIDs.production
    } else {
      return this.galleryIDs.development
    }
  }
}
