import { storageRef } from '@/firebase/db'
import user from '@/mixins/user'
import feature from '@/mixins/feature'
import Moment from 'moment'
import colors from '../design/_colors_health.scss'
import _ from 'lodash'
export default {
  mixins: [user, feature],
  computed: {
    dayList() {
      let res = []
      for (let i = 1; i < 32; i++) {
        res.push(i)
      }
      return res
    },
    monthList() {
      let res = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
      return res
    },
    yearList() {
      let years = []
      let currentYear = new Date().getFullYear()

      for (let i = currentYear - 110; i <= currentYear - 15; i++) {
        years.push(i)
      }

      return years
    }
  },
  methods: {
    parseTimestamp(ts) {
      let date = null
      if (ts && typeof ts.getMonth === 'function') {
        date = new Date(ts)
      } else if (typeof ts === 'object') {
        date = new Date(ts.seconds * 1000)
      } else {
        date = new Date(ts)
      }
      if (date == 'Invalid Date') {
        return ''
      }
      return date
    },
    formatTimestamp(ts, fmt = 'lll') {
      if (fmt === 'std') {
        fmt = 'YYYY-MM-DD h:m A'
      }
      let date
      date = this.parseTimestamp(ts)
      if (!date) {
        return ''
      }
      return Moment(date).format(fmt)
    },
    fromNow(ts, withoutSuffix = false) {
      let date = this.parseTimestamp(ts)
      if (!date) {
        return ''
      }
      return Moment(date).fromNow(withoutSuffix)
    },
    randomString(length = 15) {
      var result = []
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      var charactersLength = characters.length
      for (var i = 0; i < length; i++) {
        result.push(characters.charAt(Math.floor(Math.random() * charactersLength)))
      }
      return result.join('')
    },
    async uploadFile(file, { prefix = null, description = '', feature = '', parcelID }) {
      let user = await this.currentUser()
      user = user.uid
      let fileName = ''
      if (prefix) {
        fileName = prefix + '_'
      } else {
        fileName = this.randomString(10) + '_'
      }
      fileName += file.name
      let ref = storageRef.ref().child(`${user}/${fileName}`)
      await ref.put(file)
      let customMeta = {
        customMetadata: {
          description: description,
          feature: feature,
          filename: file.name
        }
      }
      if (parcelID) {
        customMeta.customMetadata.parcelID = parcelID
      }
      ref.updateMetadata(customMeta)
      let res = {
        url: null,
        filename: file.name,
        description: description,
        feature: feature,
        parcelID: parcelID
      }
      res.url = await ref.getDownloadURL()
      return res
    },
    newWindow(url) {
      var h = screen.height
      var w = screen.width
      window.open('', 'CPIDetail', `width=${h},height=${w}`).location = url
    },
    getNitrateColor(nitrate) {
      if (nitrate === null || isNaN(nitrate)) {
        return '#ffff'
      }
      let nitrateVal = Math.round(Number(nitrate))
      let color = ''
      switch (nitrateVal) {
        case 0:
          color = colors['health-nitrate-level-0']
          break
        case 1:
          color = colors['health-nitrate-level-1']
          break
        case 2:
          color = colors['health-nitrate-level-2']
          break
        case 3:
          color = colors['health-nitrate-level-3']
          break
        case 4:
          color = colors['health-nitrate-level-4']
          break
        case 5:
          color = colors['health-nitrate-level-5']
          break
        case 6:
          color = colors['health-nitrate-level-6']
          break
        case 7:
          color = colors['health-nitrate-level-7']
          break
        case 8:
          color = colors['health-nitrate-level-8']
          break
        case 9:
          color = colors['health-nitrate-level-9']
          break
        case 10:
          color = colors['health-nitrate-level-10']
          break
        case 11:
          color = colors['health-nitrate-level-11']
          break
        default:
          color = colors['health-nitrate-level-11']
          break
      }
      return `${color}`
    },
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    replaceInformation(data, val) {
      data = data.split('_')
      if (val) {
        data[data.length - 1] = val
      }
      return data
    },
    parseActivity(data) {
      if (!data) {
        return
      }
      let res = []
      let a = data.action
      if (a == 'create' || a == 'update' || a == 'delete') {
        res.push(this.capitalize(a) + 'd')
      }
      res.push(` ${data.type}`)
      if (data.type == 'water') {
        res.push(` test`)
      } else if (data.type == 'tax') {
        res.push(` history`)
      } else if (data.type == 'septic') {
        res.push(` maintenance`)
      } else if (data.type == 'hvac') {
        res.pop()
        res.push('Energy')
      } else if (data.type == 'hvac_information') {
        res.splice(1, 1, ...this.replaceInformation('energy_information'))
      } else if (data.type == 'water_information') {
        res.splice(1, 1, ...this.replaceInformation(data.type, 'source'))
      } else if (data.type == 'septic_information') {
        res.splice(1, 1, ...this.replaceInformation(data.type, 'system'))
      }
      return res.join(' ')
    },
    setFavicon(href) {
      var link = document.querySelector("link[rel~='icon']")
      if (!link) {
        link = document.createElement('link')
        link.rel = 'icon'
        document.getElementsByTagName('head')[0].appendChild(link)
      }
      link.href = href
    },
    getFeature(feature) {
      if (feature == 'taxes') {
        feature = 'tax'
      }
      return this.$store.getters.featuresList.filter(e => _.lowerCase(e.feature) == _.lowerCase(feature))[0]
    },
    copyObject(data) {
      return JSON.parse(JSON.stringify(data))
    },
    routePush(path) {
      if (this.$route.fullPath != path) {
        this.$router.push(path)
      }
    },
    isVowel(c) {
      let res = false
      c = c.toLowerCase()
      res = c == 'a' || c == 'e' || c == 'i' || c == 'o' || c == 'u'
      return res
    },
    randInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min)
    },
    removeCharAt(str, index) {
      var tmp = str.split('')
      tmp.splice(index, 1)
      return tmp.join('')
    },
    fmtNum(num) {
      if (Number(num)) {
        return Intl.NumberFormat().format(num)
      } else {
        return ''
      }
    },

    checkParcelTown(town, parcel) {
      let res = false
      if (parcel && parcel.townCity == town) {
        res = true
      }
      return res
    },
    downloadBase64Image(str, filename) {
      var link = document.createElement('a')
      link.href = str
      link.download = `${filename}.png`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    downloadFile(data, filename) {
      var hiddenElement = document.createElement('a')
      hiddenElement.href = data
      hiddenElement.target = '_blank'
      hiddenElement.download = filename
      hiddenElement.click()
    },
    isFeatureAvailable(ft) {
      if (this.$store.getters.user.profile.features) {
        return this.$store.getters.user.profile.features.some(e => e.value == ft && e.active)
      }
      return false
    },
    isActiveFeature(feature) {
      if (!feature) {
        return true
      }
      if (this.$store.getters.auth) {
        if (this.isFeatureAvailable(feature)) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    },
    toFixed(number, len) {
      if (!len) {
        len = 5
      }
      if (typeof number !== 'number') {
        return number
      }
      return Number(number).toFixed(len)
    },
    isNumber(val) {
      return !isNaN(Number(val))
    }
  }
}
