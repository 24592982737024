<template>
  <div class="download-btn">
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn @click="onClick" icon v-on="on" color="primary">
          <v-icon> mdi-download </v-icon>
        </v-btn>
      </template>
      <span>Download</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import parcel from '@/mixins/parcel'
export default {
  mixins: [parcel],
  props: {
    noDownload: {
      type: Boolean,
      default: false
    },
    chart: {
      type: HTMLCanvasElement
    },
    prefix: {
      type: String,
      default: 'Chart'
    }
  },
  computed: {
    ...mapGetters(['latestParcel']),
    parcelNumber() {
      if (this.latestParcel) {
        return this.parseParcelNumber(this.latestParcel.number)
      } else {
        return ''
      }
    }
  },
  methods: {
    onClick() {
      let filename = `${this.prefix}`
      if (this.parcelNumber) {
        filename += ` - ${this.parcelNumber}`
      }

      if (this.noDownload) {
        this.$emit('click', filename)
      } else {
        let b64 = ''
        if (this.base64) {
          b64 = this.base64
        } else {
          b64 = this.chart.toDataURL('image/png')
        }
        this.downloadBase64Image(b64, filename)
      }
    }
  }
}
</script>

<style scoped>
.download-btn {
  position: absolute;
  right: 0;
  top: 0;
}
</style>
