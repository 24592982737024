export default {
  state: {
    CONTACT_TYPES: [
      { text: 'Comment', value: 'comment', style: {} },
      { text: 'Suggestion', value: 'suggestion', style: {} },
      { text: 'Help Request', value: 'helpRequest', style: {} },
      { text: 'Other', value: 'other', style: {} }
    ]
  },
  getters: {
    CONTACT_TYPES(state) {
      return state.CONTACT_TYPES
    }
  }
}
