export default {
  state: {
    townsCities: [],
    states: [],
    addresses: []
  },
  getters: {
    townsCities(state) {
      return state.townsCities
    },
    states(state) {
      return state.states
    },
    addresses(state) {
      return state.addresses
    }
  },
  mutations: {
    townsCities(state, data) {
      state.townsCities = data
    },
    states(state, data) {
      state.states = data
    },
    addresses(state, data) {
      state.addresses = data
    }
  }
}
