export default {
  state: {
    septicData: {},
    septicSystem: 0,
    activeSeptic: -1,
    editSeptic: false
  },
  getters: {
    septicData(state) {
      return state.septicData
    },
    septicDataList(state) {
      let res = []
      if (
        state.septicData.septic_systems &&
        state.septicData.septic_systems[state.septicSystem]
      ) {
        res = state.septicData.septic_systems[state.septicSystem].data
      }
      res = res.map((e, i) => {
        e.index = i
        return e
      })
      return res
    },
    septicSystem(state) {
      return state.septicSystem
    },
    activeSeptic(state) {
      return state.activeSeptic
    },
    activeSepticData(state, getters) {
      return getters.septicDataList[state.activeSeptic]
    },
    editSeptic(state) {
      return state.editSeptic
    }
  },
  mutations: {
    setSepticData(state, data) {
      state.septicData = data
    },
    addSepticData(state, { item }) {
      state.septicData.septic_systems[state.septicSystem].data.push(item)
    },
    updateSepticData(state, { index, data }) {
      state.septicData.septic_systems[state.septicSystem].data.splice(
        index,
        1,
        data
      )
    },
    removeSepticData(state, { index }) {
      state.septicData.septic_systems[state.septicSystem].data.splice(index, 1)
    },
    setSepticSystemInformation(state, { data }) {
      if (state.septicData != {}) {
        state.septicData.septic_systems[
          state.septicSystem
        ].system_information = data
      }
    },
    setSepticSystem(state, index) {
      state.septicSystem = index
    },
    setActiveSeptic(state, index) {
      return (state.activeSeptic = index)
    },
    setEditSeptic(state, val) {
      state.editSeptic = val
    }
  }
}
