const MAP_STYLE_PREFIX = 'mapbox://styles/mapbox'
export const DEFAULT_MAP_STYLE = `${MAP_STYLE_PREFIX}/streets-v11`
import colors from '../design/_colors.scss'
import _ from 'lodash'
export default {
  data() {
    return {
      MAP_CONFIG: {
        styles: {
          default: DEFAULT_MAP_STYLE,
          streets: DEFAULT_MAP_STYLE,
          satellite: `${MAP_STYLE_PREFIX}/satellite-v9`,
          light: `${MAP_STYLE_PREFIX}/light-v10`,
          outdoors: `${MAP_STYLE_PREFIX}/outdoors-v11`,
          satelliteStreet: `${MAP_STYLE_PREFIX}/satellite-streets-v11`
        },
        defaultAttrs: {
          mapStyle: DEFAULT_MAP_STYLE,
          zoom: 13
        }
      }
    }
  },
  computed: {
    mapStyleList() {
      const style = this.MAP_CONFIG.styles
      let styles = []

      styles.push({
        text: 'Light',
        value: 'light',
        style: style.light
      })

      styles.push({
        text: 'Outdoor',
        value: 'outdoor',
        style: style.outdoors
      })

      styles.push({
        text: 'Satellite',
        value: 'satellite',
        style: style.satellite
      })

      styles.push({
        text: 'Street',
        value: 'street',
        style: style.streets
      })

      styles.push({
        text: 'Satellite Street',
        value: 'satelliteStreet',
        style: style.satelliteStreet
      })

      return styles
    }
  },
  methods: {
    getTextDescription(parcel) {
      let res = ``
      if (parcel.owner) {
        if (parcel.owner == 'tct') {
          res = `TCT`
        } else if (parcel.owner == 'tot') { res = `TOT` }
      }
      if (
        this.profile.includeParcelReference &&
        parcel.reference &&
        this.referenceFilter
      ) {
        res = res + `${parcel.reference}`
      }
      res = res + `\n${this.removePadding(parcel.number)}`
      if (parcel.acres && this.acresFilter) {
        res = res + `\n${parcel.acres} A`
      }
      return res
    },
    addNavigationControl(map) {
      map.addControl(new this.$mapbox.NavigationControl())
    },
    addParcelSource(map, id, data) {
      map.addSource(id, data)
    },
    addParcelCircle(map, id, source) {
      map.addLayer({
        id: id,
        type: 'circle',
        source: source,
        paint: {
          'circle-radius': 6,
          'circle-color': colors.primary
        },
        filter: ['==', '$type', 'Point']
      })
    },
    removeLayer(map, layer) {
      if (map.getLayer(layer)) {
        map.removeLayer(layer)
      }
    },
    removeSource(map, source) {
      if (map.getSource(source)) {
        map.removeSource(source)
      }
    },

    addParcelPolygon(map, id, source) {
      map.addLayer({
        id: id,
        type: 'fill',
        source: source,
        filter: ['==', '$type', 'Polygon'],
        layout: {},

        paint: {
          'fill-color': {
            type: 'identity',
            property: 'fill-color'
          },
          'fill-opacity': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            this.mapStyle == this.MAP_CONFIG.styles.satellite || this.mapStyle == this.MAP_CONFIG.styles.satelliteStreet ? 0 : 0.1,
            this.mapStyle == this.MAP_CONFIG.styles.satellite || this.mapStyle == this.MAP_CONFIG.styles.satelliteStreet ? 0 : 0.1,
          ]
        }
      })
    },

    addParcelOutline(map, id, source) {
      map.addLayer({
        id: id,
        type: 'line',
        source: source,
        layout: {},
        paint: {
          'line-color': {
            type: 'identity',
            property: 'line-color'
          },
          'line-width': 1
        }
      })
    },

    addParcelPopup(map, id) {
      const _this = this
      let popup = new this.$mapbox.Popup({
        closeButton: false,
        closeOnClick: false,
        className: 'mapboxgl-popup--opacity-60'
      })
      map.on('mousemove', id, function (e) {
        map.getCanvas().style.cursor = 'pointer'
        let description = `GPS: ${_this.toFixed(e.lngLat.lat)}, ${_this.toFixed(
          e.lngLat.lng
        )}`

        popup
          .setLngLat(e.lngLat)
          .setHTML(description)
          .addTo(map)
      })

      map.on('mouseleave', id, function () {
        map.getCanvas().style.cursor = ''
        popup.remove()
      })
    },

    addParcelHoverEffect(map, id, source) {
      let hoverId = null
      map.on('mousemove', id, function (e) {
        if (e.features.length > 0) {
          if (hoverId !== null) {
            map.setFeatureState({
              source: source,
              id: hoverId
            }, {
              hover: false
            })
          }
          hoverId = e.features[0].id
          map.setFeatureState({
            source: source,
            id: hoverId
          }, {
            hover: true
          })
        }
      })

      map.on('mouseleave', id, function () {
        if (hoverId !== null) {
          map.setFeatureState({
            source: source,
            id: hoverId
          }, {
            hover: false
          })
        }
        hoverId = null
      })
    },

    parcelPolygonGeojson(parcel) {
      let parcelsFiltered =
        typeof parcel.geojson === 'string' ?
          JSON.parse(parcel.geojson) :
          parcel.geojson
      if (!parcelsFiltered.properties) {
        parcelsFiltered.properties = {}
      }
      parcelsFiltered.properties.description = this.getTextDescription(parcel)
      parcelsFiltered.properties.owner = parcel.owner
      parcelsFiltered.properties['fill-color'] = this.mapLayerColor(
        parcel.owner
      )
      parcelsFiltered.properties['line-color'] = this.mapLayerColor(
        parcel.owner
      )
      parcelsFiltered.properties['text-color'] = this.mapLayerColor()

      return {
        ...parcelsFiltered,
        id: parcel.index
      }
    },
    parcelCircleGeojson(parcel) {
      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: parcel.center
        }
      }
    },

    addLabelLayer(map, id, source) {
      map.addLayer({
        id: id,
        type: 'symbol',
        source: source,
        paint: {
          'text-color': {
            type: 'identity',
            property: 'text-color'
          }
        },
        layout: {
          'text-field': ['get', 'description'],
          'text-justify': 'center'
        }
      })
    },
    getBbox(bboxList) {
      let minLng = []
      let minLat = []
      let maxLng = []
      let maxLat = []

      bboxList.map(i => {
        minLng.push(i[0])
        minLat.push(i[1])
        maxLng.push(i[2])
        maxLat.push(i[3])
      })

      minLng = _.min(minLng)
      minLat = _.min(minLat)
      maxLng = _.max(maxLng)
      maxLat = _.max(maxLat)

      const bbox = [minLng, minLat, maxLng, maxLat]
      return bbox
    },
    bboxListFromParcels(parcels) {
      return parcels.map(e => e.bbox).filter(e => !!e)
    }
  }
}
