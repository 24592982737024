import AdminHeader from '@/components/AdminHeader'
import AppFooter from '@/components/AppFooter'

const Dashboard = () =>
  import(/* webpackChunkName: "Dashboard" */ '@/views/Admin/Dashboard')
const UserDetail = () =>
  import(/* webpackChunkName: "UserDetail" */ '@/views/Admin/User/Index')
const Sandbox = () =>
  import(/* webpackChunkName: "Sandbox" */ '@/views/Admin/Sandbox/Index')

const routes = [
  {
    path: '/admin/dashboard',
    name: 'Dashboard',
    meta: {
      admin: true
    },
    components: {
      header: AdminHeader,
      default: Dashboard,
      footer: AppFooter
    }
  },
  {
    path: '/admin/user/:id',
    name: 'UserDetail',
    meta: {
      admin: true
    },
    components: {
      header: AdminHeader,
      default: UserDetail,
      footer: AppFooter
    }
  },
  {
    path: '/admin/sandbox',
    name: 'Sandbox',
    meta: {
      admin: true
    },
    components: {
      header: AdminHeader,
      default: Sandbox,
      footer: AppFooter
    }
  }
]
export default routes
