<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <span v-on="on"
          ><v-icon color="primary">mdi-arrow-left</v-icon> Parcels</span
        >
      </template>
      <span>Click to go back to PARCEL page.</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
