import GalleryIcons from './Gallery'

import Oil from './Oil'
import Propane from './Propane'
import Wood from './Wood'
import SepticTank from './SepticTank'
import Electric from './Electric'
import Solar from './Solar'

const Icons = {
  septicTank: {
    component: SepticTank
  },
  oil: {
    component: Oil
  },
  propane: {
    component: Propane
  },
  wood: {
    component: Wood
  },
  electric: {
    component: Electric
  },
  solar: {
    component: Solar
  },
  ...GalleryIcons
}

export default Icons
