<template>
  <div class="bill-container">
    <base-file-upload
      :error.sync="billError"
      :file.sync="billFile"
      @update:file="$emit('update:file', billFile)"
      @update:error="$emit('update:error', billError)"
    >
      <template v-slot:title><span></span></template>
      <template
        v-if="typeof report == 'object' && report.filename"
        v-slot:header
      >
        <!-- <template v-if="report.filename" v-slot:header> -->
        <p class="text-center">
          <span class="primary--text cursor-pointer" @click="showURL()">{{
            report.filename
          }}</span>
        </p>
        <v-divider class="header-divider"></v-divider>
      </template>
      <template v-slot:footer>
        <v-textarea
          @input="$emit('update:description', fileDescription)"
          rows="1"
          :label="documentDescription"
          auto-grow
          v-model="fileDescription"
          v-if="showDescription"
        ></v-textarea>
        <v-alert color="error" dark v-if="report.url && file">
          <strong>{{ file.name }}</strong> will overwrite old file.
        </v-alert>
      </template>
    </base-file-upload>
  </div>
</template>

<script>
import utils from '@/mixins/utils'
export default {
  mixins: [utils],
  // props: ['report', 'file', 'error', 'showDescription', 'description'],
  props: {
    report: {},
    file: {},
    error: {},
    showDescription: {
      type: Boolean
    },
    description: {},
    documentDescription: {
      type: String,
      default: 'Document Description'
    }
  },
  components: {
    BaseFileUpload: () => import('@/components/BaseFileUpload')
  },
  mounted() {
    this.billError = this.error
    this.setDescription()
  },
  data: () => ({
    billFile: false,
    billError: false,
    fileDescription: ''
  }),
  methods: {
    showURL() {
      this.newWindow(this.report.url)
    },
    setDescription() {
      this.fileDescription = this.description
    }
  },
  watch: {
    error() {
      this.billError = this.error
    },
    file() {
      this.billFile = this.file
    },
    description() {
      this.setDescription()
    }
  }
}
</script>

<style scoped>
.bill-container {
  margin-top: -14px !important;
  margin-bottom: 10px !important;
}
.header-divider {
  margin-top: -10px;
  margin-bottom: 10px;
}
</style>
