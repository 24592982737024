export default {
  state: {
    dataList: [],
    data: { solar_systems: [] },
    selectedSystem: -1,
    loading: false,
    options: {
      where: ['Roof', 'Ground'],
      typesOfWork: ['Solar']
    },
    status: {},
    log: [],
    adminImport: {
      count: 0,
      completed: 0
    }
  },
  getters: {
    data(state) {
      return state.data
    },
    dataList(state) {
      return state.dataList
    },
    systems(state) {
      state.data.solar_systems.map((e, i) => {
        e.index = i
      })
      return state.data.solar_systems
    },
    selectedSystem(state) {
      return state.selectedSystem
    },
    selectedSystemData(state) {
      if (state.selectedSystem == -1) {
        return null
      } else {
        return state.data.solar_systems[state.selectedSystem]
      }
    },
    loading(state) {
      return state.loading
    },
    options(state) {
      return state.options
    },
    status(state) {
      return state.status
    },
    log(state) {
      return state.log
    },
    adminImport(state) {
      return state.adminImport
    }
  },
  mutations: {
    setData(state, data) {
      state.data = data
    },
    setSystems(state, data) {
      state.data.solar_systems = data
    },
    addSystem(state, data) {
      state.data.solar_systems.push(data)
    },
    removeSystem(state) {
      state.data.solar_systems.splice(state.selectedSystem, 1)
    },
    updateSystem(state, data) {
      state.data.solar_systems.splice(state.selectedSystem, 1, data)
    },
    setSelectedSystem(state, index) {
      state.selectedSystem = index
    },
    setLoading(state, value) {
      state.loading = value
    },
    setLog(state, data) {
      state.log = data
    },
    addLog(state, log) {
      state.log.push(log)
    },
    setStatus(state, data) {
      state.status = data
    },
    setAdminImport(state, { key, value }) {
      state.adminImport[key] = value
    },
    setDataList(state, data) {
      state.dataList = data
    },
    addDataList(state, data) {
      state.dataList.push(data)
    }
  },
  actions: {
    async clearData({ commit }) {
      let data = { solar_systems: [] }
      commit('setData', data)
    }
  }
}
