<template>
  <div>
    <v-dialog v-model="dialogs.detail" max-width="600" max-height="80vh">
      <GalleryButtonClose v-model="dialogs.detail"></GalleryButtonClose>
      <div v-if="activePost">
        <PostDetail
          :galleryRef="galleryRef"
          :id="activePost.id"
          :key="activePost.id"
          @close="dialogs.detail = false"
        />
      </div>
    </v-dialog>

    <div class="alerts">
      <v-fade-transition>
        <v-alert type="info" v-show="info" text>{{ info }}</v-alert>
      </v-fade-transition>
      <v-fade-transition>
        <v-alert type="error" v-show="error" text>{{ error }}</v-alert>
      </v-fade-transition>
    </div>
    <div>
      <PostsGroup
        :items="posts"
        :galleryRef="galleryRef"
        :galleryCommentRef="galleryCommentRef"
        :show-date-shared="showDate"
        @showDetail="showDetail"
      />
    </div>
  </div>
</template>

<script>
import PostsGroup from './PostGroup.vue'
import PostDetail from './PostDetail.vue'
import GalleryButtonClose from './ButtonClose.vue'
import { galleryCommentRef } from '@/services/firestore'
import config from '../../config'
export default {
  components: {
    PostsGroup,
    PostDetail,
    GalleryButtonClose
  },
  props: {
    parcel: { type: String, required: true },
    galleryRef: {
      type: Object,
      required: true
    },
    category: {
      type: String,
      required: true
    },
    showDate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogs: { detail: false },
      galleryCommentRef,
      posts: [],
      loading: true,
      error: '',
      info: '',
      activePost: null,
      unsubscribe: null
    }
  },
  beforeMount() {
    this.getPosts()
  },
  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  },
  methods: {
    showDetail(obj) {
      this.activePost = obj
      this.dialogs.detail = true
    },
    async getPosts() {
      this.loading = true
      try {
        this.unsubscribe = this.galleryRef
          .where('galleryId', '==', config.galleryID())
          .where('parcelId', '==', this.parcel)
          .where('type', '==', this.category)
          .onSnapshot(
            res => {
              this.error = ''
              this.info = ''
              const _posts = []
              res.forEach(i => {
                _posts.push({ ...i.data(), id: i.id })
              })
              this.posts = _posts
              this.$emit('update:count', _posts.length)
              if (this.posts.length === 0) {
                this.info = 'No images found'
              }
            },
            error => {
              this.error = error.message
            }
          )
      } catch (error) {
        console.error(error)
        this.error = error.message
      }
      this.loading = false
    }
  }
}
</script>

<style></style>
