<template>
  <div class="d-flex alert" :style="{ 'max-width': width }">
    <div class="alert-container">
      <v-alert
        :class="`alert ${cursorPointer ? 'cursor-pointer' : ''}`"
        :color="color"
        :outlined="outlined"
        v-bind="$attrs"
        @click="onClick"
      >
        <slot></slot
      ></v-alert>
    </div>
    <div class="img-container">
      <img class="image" src="@/assets/img/PeepsAbove.svg" alt="" />
    </div>
  </div>
</template>

<script>
/*
Alert component with peeps on right side

Props:
  - Width
      css type width for alert
      Example: 10px, 5rem, 50%
  - Color
      Same colors that vuetify alert component uses, default is secondary.
      success, error, warning, info, secondary
  - Outlined
      Vuetify alert component outlined props, either true or false
*/
export default {
  props: {
    width: { type: String },
    color: { type: String, default: 'secondary' },
    outlined: {
      type: Boolean,
      default: true
    },
    cursorPointer: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    onClick() {
      this.$emit('click')
    }
  }
}
</script>

<style scoped>
.alert {
  margin-top: 22px;
}
.alert-container {
  width: 100%;
  z-index: 1;
}
.img-container {
  z-index: 1;
  position: absolute;
}
.image {
  height: 58px !important;
  margin-top: -25.6px;
}
</style>
