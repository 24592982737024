<template>
  <div>
    <v-form id="form" ref="form" v-model="valid">
      <div class="mt-5">
        <v-row>
          <v-col
            v-show="
              showField('firstName') &&
              (update || !loggedIn || !formData.firstName)
            "
            cols="6"
          >
            <v-text-field
              v-model="formData.firstName"
              :rules="rules.requiredRule"
              outlined
              label="First Name"
            ></v-text-field>
          </v-col>
          <v-col
            v-show="
              showField('lastName') &&
              (update || !loggedIn || !formData.lastName)
            "
            cols="6"
          >
            <v-text-field
              v-model="formData.lastName"
              :rules="rules.requiredRule"
              outlined
              label="Last Name"
            ></v-text-field>
          </v-col>
          <v-col
            v-show="
              showField('email') && (update || !loggedIn || !formData.email)
            "
            cols="12"
            sm="6"
            class="mt-n5"
          >
            <v-text-field
              v-model="formData.email"
              :rules="rules.emailRule"
              outlined
              label="Email"
            ></v-text-field>
          </v-col>
          <v-col
            v-show="showField('category')"
            cols="12"
            :sm="!(update || !loggedIn) ? '12' : '6'"
            class="mt-n5"
          >
            <div>
              <v-autocomplete
                v-model="formData.type"
                outlined
                label="Category"
                :rules="rules.requiredRule"
                :items="categories"
              ></v-autocomplete>
              <div class="mb-3 mt-n5">
                <slot name="add-category"> </slot>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
      <div>
        <v-textarea
          v-show="showField('caption')"
          v-model="formData.caption"
          outlined
          auto-grow
          :rows="2"
          label="Caption"
        ></v-textarea>
        <div>
          <v-textarea
            v-show="showField('subcaption')"
            v-model="formData.subCaption"
            label="Subcaption"
            rows="3"
            auto-grow
            outlined
          ></v-textarea>
        </div>
      </div>
      <v-fade-transition>
        <div v-show="showField('parcel') && showParcelInput">
          <v-text-field v-model="formData.parcelId" outlined label="Parcel ID">
          </v-text-field>
        </div>
      </v-fade-transition>
      <v-fade-transition>
        <div>
          <div v-show="showField('artDescription') && formData.forSale">
            <div>
              <div class="tiptap-container mt-n3">
                <label for="subcaption" class="text-h6">Art Description</label>
                <base-text-editor
                  id="art-description"
                  v-model="formData.artDescription"
                  class="mt-1"
                  placeholder="Art Description"
                ></base-text-editor>
              </div>
            </div>
          </div>
          <div class="d-flex">
            <v-checkbox
              v-show="
                showField('privacy') &&
                (formData.forSale || userSetting.enablePrivacy)
              "
              v-model="formData.private"
              class="mt-0"
              label="Private"
            ></v-checkbox>
            <v-checkbox
              v-show="showField('sold') && formData.forSale"
              v-model="formData.sold"
              class="ml-3 mt-0"
              label="Sold"
            ></v-checkbox>
          </div>
        </div>
      </v-fade-transition>
      <v-row>
        <v-col v-show="showField('hideName')" cols="8">
          <v-checkbox
            v-model="formData.hideName"
            class="mt-n1"
            label="Don't add my name under my caption"
          ></v-checkbox>
        </v-col>
        <v-col
          v-if="showField('forSale') && loggedIn && userSetting.sale"
          cols="4"
        >
          <div class="d-flex">
            <v-checkbox v-model="formData.forSale" class="mt-n1">
              <template #label> For Sale </template>
            </v-checkbox>
            <v-btn
              style="margin-top: -5.5px"
              color="primary"
              icon
              @click="dialogs.forSale = true"
            >
              <v-icon>mdi-information</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <div v-if="showField('imageUpload') && showFileUpload">
        <image-upload
          ref="imageUpload"
          v-model="validImages"
          :required="photoRequired"
          :converting.sync="converting"
          :files.sync="formData.files"
          :ids.sync="formData.images"
        ></image-upload>
      </div>
      <slot name="footer"></slot>

      <v-scroll-y-transition>
        <v-alert v-if="success" type="success" dismissible>{{
          success
        }}</v-alert>
      </v-scroll-y-transition>
      <v-scroll-y-transition>
        <v-alert v-if="error" type="error" dismissible>{{ error }}</v-alert>
      </v-scroll-y-transition>

      <div class="d-sm-flex">
        <v-spacer></v-spacer>

        <div class="text-center mt-3">
          <v-btn
            color="primary"
            x-large
            class="text-capitalize"
            :loading="loading"
            :disabled="converting && photoRequired"
            @click="onSubmit()"
          >
            {{ label }}
          </v-btn>

          <v-btn
            class="text-capitalize"
            text
            color="grey"
            x-large
            @click="onCancel()"
          >
            Cancel
          </v-btn>
        </div>

        <v-spacer></v-spacer>
        <slot name="technicalDifficulty"> </slot>
      </div>
      <div class="text-left mt-2" v-show="showFooter">
        <span class="light-black--text">
          <small>
            For responsive images, please keep images size between 100 KB and
            500 KB if possible. <br />
            10 MB is maximum size that should be uploaded.
          </small>
        </span>
        <br />
        <span
          v-if="geolocation && userSetting.gps && !update"
          class="light-black--text"
        >
          <small>
            Your GPS location is
            <strong>{{ geolocation.latitude.toFixed(5) }}</strong
            >,
            <strong>
              {{ geolocation.longitude.toFixed(5) }}
            </strong>
          </small>
        </span>
      </div>
    </v-form>
  </div>
</template>

<script>
import ImageUpload from './ImageUpload.vue'
export default {
  components: {
    ImageUpload
  },
  props: {
    // required props
    loggedIn: {
      type: Boolean,
      required: true
    },
    categories: {
      type: Array,
      required: true
    },
    userSetting: {
      type: Object,
      required: true
    },
    galleryRef: {
      type: Object,
      required: true
    },
    photoRequired: {
      type: Boolean,
      required: true
    },

    // optional props
    gallery: {
      type: Object,
      default: () => {
        return {}
      }
    },
    userData: {
      type: Object,
      default: null
    },
    selectedCategory: {
      type: String,
      default: ''
    },
    data: {
      type: Object,
      default: null
    },
    showFileUpload: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: 'Submit'
    },
    update: {
      type: Boolean,
      default: false
    },
    inputParcel: {
      type: Boolean,
      default: false
    },
    admin: {
      type: Boolean,
      default: false
    },
    website: {
      type: String,
      default: ''
    },
    fields: {
      type: Array,
      default: () => {
        return []
      }
    },
    showFooter: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      rules: {
        emailRule: [
          v => !!v || 'Enter a valid email',
          v => /.+@.+\..+/.test(v) || 'Enter a valid email'
        ],
        requiredRule: [v => !!v || 'This field is required'],
        passwordRule: [
          v =>
            (!!v && v.length >= 6) || 'Password should be at least 6 characters'
        ]
      },
      formData: {
        files: [],
        firstName: '',
        lastName: '',
        email: '',
        type: '',
        caption: '',
        subCaption: '',
        forSale: false,
        hideName: false,
        public: true,
        private: false,
        sold: false,
        website: '',
        galleryId: 'public',
        parcelId: ''
      },
      dialogs: {
        forSale: false
      },
      valid: true,
      validImages: true,
      converting: false,
      loading: false,
      success: '',
      error: '',
      errors: {
        convert: [],
        imageRequired: false
      },
      geolocation: null
    }
  },
  computed: {
    showParcelInput() {
      if (this.admin) {
        return true
      }
      if (this.inputParcel) {
        return true
      }
      if (this.gallery && this.gallery.inputParcel) {
        return true
      }
      return false
    },
    isGalleryPublic() {
      if (!this.gallery || !this.gallery.id) {
        return true
      }
      if (String(this.gallery.id).toLowerCase() === 'public') {
        return true
      }
      return false
    }
  },
  watch: {
    gallery: {
      handler(val) {
        if (this.update) {
          return
        }
        if (!val) {
          this.formData.galleryId = 'public'
        } else {
          this.formData.galleryId = val.id
        }
      },
      immediate: true,
      deep: true
    },
    selectedCategory: {
      handler(val) {
        if (!this.update) {
          this.formData.type = val
        }
      },
      immediate: true,
      deep: true
    },
    userData: {
      handler: 'setUserData',
      immediate: true,
      deep: true
    },
    data: {
      handler: 'setDefaultData',
      immediate: true,
      deep: true
    }
  },
  beforeMount() {
    this.setDefaultData()
    this.setUserData()
  },
  mounted() {
    this.getCoordinates()
    this.checkSlots()
  },
  methods: {
    checkSlots() {
      const SLOTS = {
        addCategory: 'Add category component is missing',
        technicalDifficulty: 'Technical difficulty component is missing'
      }
      Object.keys(SLOTS).forEach(k => {
        if (!this.$slots[k]) {
          console.warn(SLOTS[k])
        }
      })
    },

    setData(data) {
      if (!data || typeof data !== 'object') {
        return
      }
      Object.keys(data).forEach(k => {
        this.formData[k] = data[k] || this.formData[k]
      })
    },
    setDefaultData() {
      this.setData(this.data)
    },
    setUserData() {
      if (this.update) {
        return
      }
      this.setData(this.userData)
    },

    async getCoordinates() {
      try {
        await this.userProfile()
        // eslint-disable-next-line no-empty
      } catch {}

      if (!this.userSetting) {
        return
      }

      if (!this.loggedIn || !this.userSetting.gps) {
        return
      }

      try {
        this.geolocation = await getCoordinates()
      } catch (error) {
        let msg = ''
        if (error.code === 1) {
          msg = 'Location access denied'
        } else {
          msg = error.message
        }
        // this.$toasted.show(msg, { type: "error" });
        this.error = msg
      }
    },
    submit() {},
    onSubmit() {
      this.$refs.form.validate()

      if (process.env.NODE_ENV === 'development') {
        console.log('----------------------------------')
        console.log('Submit', this)
        console.log('----------------------------------')
      }

      if (this.loggedIn && this.website) {
        this.formData.website = this.website
      }

      if (this.showFileUpload) {
        this.$refs.imageUpload.validate()
      }

      if (this.valid) {
        if (this.showFileUpload && !this.validImages) {
          return
        }

        Object.keys(this.formData).forEach(key => {
          if (this.formData[key] === null || this.formData[key] === undefined) {
            this.formData[key] = ''
          }
        })
        this.submit()
      }
    },

    resetForm() {
      const _default = defaultFormData()
      Object.keys(_default).forEach(k => {
        this.formData[k] = _default[k]
      })
      this.setUserData()
      this.setDefaultData()
      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      } else {
        console.info('No form found')
      }
    },

    onCancel(emit = true) {
      this.resetForm()

      if (this.showFileUpload) {
        this.$refs.imageUpload.clearFiles()
      }

      if (emit) {
        this.$emit('cancel')
      }
    },
    clearAlert() {
      setTimeout(() => {
        this.success = null
        this.error = null
      }, 5000)
    },
    removeFile(file) {
      for (const i in this.formData.files) {
        if (this.formData.files[i].file === file.file) {
          this.formData.files.splice(i, 1)
          this.formData.images.splice(i, 1)
        }
      }
    },
    showField(field) {
      if (this.fields.length === 0) {
        return true
      }
      return this.fields.includes(field)
    }
  }
}

// ------- Location Services ---------
function GeoLocationNotSupported(message) {
  this.message = message
  return this
}
GeoLocationNotSupported.prototype = Error.prototype

const getCoordinates = () => {
  return new Promise((resolve, reject) => {
    if (!navigator.geolocation) {
      reject(
        new GeoLocationNotSupported(
          'Your browser does not support HTML5 geolocation.'
        )
      )
    }
    const res = {
      latitude: null,
      longitude: null
    }

    navigator.geolocation.getCurrentPosition(
      pos => {
        res.latitude = pos.coords.latitude
        res.longitude = pos.coords.longitude
        resolve(res)
      },
      error => {
        reject(error)
      }
    )
  })
}
// ------- Location Services ---------

const defaultFormData = () => {
  return {
    files: [],
    firstName: '',
    lastName: '',
    email: '',
    type: '',
    caption: '',
    subCaption: '',
    forSale: false,
    hideName: false,
    public: true,
    private: false,
    sold: false,
    website: '',
    galleryId: 'public',
    parcelId: ''
  }
}
</script>

<style scoped lang="scss">
@media (max-width: 600px) {
  .feedback-button {
    margin-top: 2rem !important;
  }
}
</style>
