import Vue from 'vue'
import '@/components/Core/'

import CancelButton from '@/components/Core/CancelButton'
import DateHover from '@/components/Core/DateHover'
import CloseWindow from '@/components/Core/CloseWindow'
import PasswordField from '@/components/Core/PasswordField'

import AboutFeatures from '@/components/Core/About/Features'
import SwiftScan from '@/components/SwiftScan'
import FileUpload from '@/components/FileUpload'

Vue.component('cancel-button', CancelButton)
Vue.component('date-hover', DateHover)
Vue.component('close-window', CloseWindow)
Vue.component('password-field', PasswordField)
Vue.component('swift-scan', SwiftScan)

Vue.component('about-features', AboutFeatures)

Vue.component('file-upload', FileUpload)
