import Vue from 'vue'
import './Features'
import './Chip'
import './Chart'
import './Base'
import './Alerts'
import './Swiper'

import DateField from './DateField'
import PhoneNumber from './PhoneNumber'
import BreadcrumbParcel from './BreadcrumbParcel'
import TableFilter from './TableFilter'
import DeleteConfirmation from './DeleteConfirmation'
import FeatureBreadcrumb from './FeatureBreadcrumb'
import ScreenSize from './ScreenSize'
import DownloadChart from './DownloadChart'
import BlobFeature from './BlobFeature'
import AlertMessage from './AlertMessage.vue'

Vue.component('date-field', DateField)
Vue.component('phone-number', PhoneNumber)
Vue.component('breadcrumb-parcel', BreadcrumbParcel)
Vue.component('table-filter', TableFilter)
Vue.component('delete-confirmation', DeleteConfirmation)
Vue.component('feature-breadcrumb', FeatureBreadcrumb)
Vue.component('screen-size', ScreenSize)
Vue.component('download-chart', DownloadChart)
Vue.component('blob-feature', BlobFeature)
Vue.component('alert-message', AlertMessage)
