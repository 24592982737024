import { featureRef } from '@/firebase/db'
import user from '@/mixins/user'
export default {
  mixins: [user],
  methods: {
    async setFeature(doc, data) {
      await featureRef.doc(doc).set(data)
    },
    async getFeatures(doc) {
      let res = await featureRef.doc(doc).get()
      if (res.exists) {
        return res.data()
      } else {
        return null
      }
    },
    async defaultFeatures() {
      let features = await this.getFeatures('default')
      return features
    },
    async setDefaultFeatures() {
      if (!this.$store.getters.features.length) {
        let ft = await this.defaultFeatures()
        this.$store.commit('features', ft.data)
      }
    },
    async initFeatures(user) {
      let features = await this.defaultFeatures()
      features = [...features.data]
      for (let feat of features) {
        if (!feat.disabled) {
          feat.active = true
        } else {
          feat.active = false
        }
        delete feat.disabled
      }
      await this.changeProfileData({ features: features }, user)
    }
    // isFeatureDisabled (feature) {
    //     let res = false;
    //     this.$store.getters.features.forEach((ft) => {
    //         if (ft.value == feature.value) {
    //             if (ft.disabled) {
    //                 res = true;
    //             }
    //         }
    //     });
    //     return res;
    // },
    // async validateFeatures () {
    //     let userProfile = await this.userProfile();
    //     console.log(userProfile)
    //     // this.forEach((feature) => {
    //     //     feature.disabled = this.isFeatureDisabled(feature);
    //     //     if (feature.disabled) {
    //     //         feature.active = false;
    //     //     }
    //     // });
    //     // this.changeProfileData(
    //     //     { features: this.features },
    //     //     await this.currentUser()
    //     // );

    // }
  }
}
