<template>
  <v-chip
    v-if="value"
    v-bind="$attrs"
    :color="color ? color : value.style.color || 'primary'"
    :dark="value.style.dark ? true : false"
  >
    {{ shortName ? value.shortName : value.text }}
  </v-chip>
</template>

<script>
export default {
  props: {
    value: {
      type: Object
    },
    shortName: {
      type: Boolean,
      default: true
    },
    color: {
      type: String
    }
  }
}
</script>

<style></style>
