import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import '@/plugins/vuemeta'
import '@/plugins/components'
import '@/plugins/clipboard'
import '@/plugins/quill'

import './plugins/firebase'
import '@/assets/css/main.css'
import '@/assets/css/card.sass'
import '@/plugins/viewer'
import '@/plugins/axios'
import commonMixins from '@/plugins/common_mixins'
import './components/Gallery/index'
import config from './config'

Vue.use(commonMixins)

Vue.config.productionTip = false

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

import 'mapbox-gl/dist/mapbox-gl.css'
import mapboxgl from 'mapbox-gl'

mapboxgl.accessToken = config.mapboxToken
Vue.prototype.$mapbox = mapboxgl
Vue.prototype.$bus = new Vue()

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
