<template>
  <span>
    <v-icon
      v-if="feature"
      :color="useColor ? feature.color : ''"
      :class="feature.class"
      v-bind="$attrs"
      >{{ feature.icon }}</v-icon
    >
  </span>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true
    },
    useColor: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    feature() {
      let f = this.getFeature(this.value)
      if (!f) {
        return null
      }
      return f.style
    }
  }
}
</script>

<style></style>
