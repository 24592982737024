<template>
  <div>
    <div>
      <input id="file" type="file" multiple />
    </div>
  </div>
</template>
<script>
import Vue from 'vue'

import VueCompositionAPI, {
  defineComponent,
  reactive,
  computed,
  onMounted,
  toRefs,
  ref,
  watch
} from '@vue/composition-api'

Vue.use(VueCompositionAPI)
// import { uploadFile, STORAGE, getDownloadURL } from '../services/storage';
import * as FilePond from 'filepond'
import 'filepond/dist/filepond.min.css'
// import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'

import FFile from '../../../services/file'
const ffile = new FFile()

FilePond.registerPlugin(FilePondPluginFileValidateSize)
// FilePond.registerPlugin(FilePondPluginImagePreview);
FilePond.registerPlugin(FilePondPluginFileValidateType)

const createFilePond = (el, onProcess) => {
  const inputElement = document.querySelector(el)
  return FilePond.create(inputElement, {
    allowFileSizeValidation: true,
    maxFileSize: '100MB',
    allowFileTypeValidation: true,
    acceptedFileTypes: [],
    allowRevert: false,
    labelIdle: `
        Drag &amp; Drop your files or <span class="filepond--label-action" tabindex="0">Browse</span>`,

    server: {
      process: async (fieldName, file, metadata, load, error) => {
        try {
          await onProcess({ file, metadata })
          load()
        } catch (err) {
          error()
          throw err
        }
      }
    }
  })
}

export default defineComponent({
  name: 'ImageUpload',

  props: {
    required: {
      type: Boolean,
      default: true
    },
    user: {
      type: String,
      required: true
    },
    folderId: {
      type: [String, null],
      default: null
    },
    description: {
      type: String,
      default: ''
    }
  },
  setup(props, context) {
    let pond

    const files = ref([])
    const valid = ref(true)

    const data = reactive({
      errorBag: { required: { valid: true, detail: '' } },
      resetForm: false
    })

    watch(
      files,
      val => {
        context.emit('update:files', val)
      },
      { immediate: true }
    )
    watch(
      valid,
      val => {
        context.emit('input', val)
      },
      { immediate: true }
    )

    /* ----------- Validation -----------  */
    const resetValidation = () => {
      valid.value = true
      Object.keys(data.errorBag).forEach(key => {
        data.errorBag[key].valid = true
      })

      context.emit('input', valid.value)
    }

    const validate = () => {
      resetValidation()

      if (props.required) {
        if (!files.value.length) {
          data.errorBag.required = reactive({
            valid: false,
            detail: 'At least one image is required'
          })
          valid.value = false
        }
      }

      context.emit('input', valid.value)
    }

    /* ----------- Upload -----------  */
    // uplaod image to cloud storage
    const onProcessFile = async ({ file }) => {
      const path = await ffile.uploadFile({ file, user: props.user })
      const url = await ffile.getDownloadURL(path)
      ffile.updateMetadata(path, {
        name: file.name,
        user: props.user,
        description: props.description
      })
      const fileInfo = {
        name: `${file.name}`,
        path,
        url,
        size: file.size
      }

      files.value.push(fileInfo)

      context.emit('upload', fileInfo)

      // data.errorBag.required.valid = true;
    }
    // remove image

    const clearFiles = async () => {
      data.resetForm = true
      await pond.removeFiles()
      setTimeout(() => {
        data.resetForm = false
      }, 3000)
      resetValidation()
    }

    onMounted(() => {
      pond = createFilePond('#file', onProcessFile)
    })

    return {
      validate,
      resetValidation,
      clearFiles,
      files,
      valid,
      ...toRefs(data)
    }
  }
})
</script>

<style>
</style>
