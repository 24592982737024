<template>
  <v-chip small dark :color="color" v-bind="$attrs" class="cursor-pointer"
    ><slot
      >{{ source }}
      <v-icon class="ml-2"> mdi-folder-information-outline </v-icon>
    </slot></v-chip
  >
</template>

<script>
export default {
  props: {
    source: {
      type: String,
      default: ''
    }
  },
  computed: {
    profile() {
      return this.$store.getters.user.profile
    },
    color() {
      let color = this.getFeature('water').style.color
      if (this.profile.settings && this.profile.settings.chip_color) {
        if (this.profile.settings.chip_color.water) {
          color = this.profile.settings.chip_color.water
        }
      }
      return color
    }
  }
}
</script>

<style></style>
