<template>
  <div>
    <div class="d-flex justify-space-between align-center">
      <div>
        <div class="d-flex align-center">
          <v-breadcrumbs :items="breadcrumbs">
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item
                :exact="item.exact"
                :disabled="item.disabled"
                :to="item.to"
              >
                <slot
                  v-if="item.text == feature.text"
                  :item="item"
                  name="default"
                >
                  {{ item.text }}
                </slot>
                <breadcrumb-parcel
                  v-else-if="item.text == 'Parcels'"
                ></breadcrumb-parcel>
                <span v-else>
                  {{ item.text }}
                </span>
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
          <div class="mr-2">
            <slot name="action"></slot>
          </div>
        </div>
      </div>
      <div style="max-width: 80px">
        <v-select
          label="Add data for"
          v-model="selectedFeature"
          :items="otherFeatures"
          item-text="text"
          item-value="feature"
        ></v-select>
      </div>
    </div>
  </div>
</template>

<script>
import parcel from '@/mixins/parcel'
export default {
  mixins: [parcel],
  props: {
    feature: {
      type: Object
    },
    showAddress: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    parcel: null,
    selectedFeature: null
  }),
  computed: {
    parcelNumber() {
      let res = 'Loading'
      if (this.parcel) {
        res = this.parcel.number
      }
      return res
    },
    breadcrumbs() {
      return [
        {
          text: 'Parcels',
          to: '/parcels',
          exact: true
        },
        {
          text: this.parcelNumber,
          to: '#',
          disabled: true
        },
        {
          ...this.feature
        }
      ]
    },
    streetAddressComputed() {
      let res = ''
      if (this.parcel.street_number) {
        res += `${this.parcel.street_number}`
        res += ` `
      }
      if (this.parcel.street_name) {
        res += `${this.parcel.street_name}`
      }
      return res
    },
    currentFeature() {
      let feature = this.$route.path
      feature = feature.split('/')
      feature = feature[feature.length - 1]
      return this.getFeature(feature)
    },
    otherFeatures() {
      let f = this.$store.getters.featuresList
      f = f.filter(e => this.isActiveFeature(e.feature))
      // f = f.filter(e=>e.feature!=this.currentFeature.feature)
      return f
    }
  },
  async mounted() {
    if (this.latestParcel && this.latestParcel.id == this.$route.params.id) {
      this.parcel = this.copyObject(this.latestParcel)
    } else {
      this.parcel = await this.getParcelDetail(this.$route.params.id, true)
    }
    this.$store.commit('latestParcel', this.parcel)
    this.breadcrumbs[1].text =
      this.parcel.number + ` ` + this.streetAddressComputed
  },
  methods: {
    setFeature() {
      this.selectedFeature = this.currentFeature.feature
    },
    onFeatureChange() {
      this.$router.push(
        `/parcels/${this.$route.params.id}/${
          this.getFeature(this.selectedFeature).url
        }`
      )
    },
    featureLink(f) {
      return `/parcels/${this.$route.params.id}/${f.url}`
    }
  },
  watch: {
    currentFeature: {
      handler: 'setFeature',
      immediate: true
    },
    selectedFeature: {
      handler: 'onFeatureChange'
    }
  }
}
</script>

<style scoped></style>
