import firebase from 'firebase/app'
import 'firebase/auth'
import { userRef, eventRef } from '@/firebase/db'
import { mapGetters } from 'vuex'
import config from '../config'
const api = config.api()
export default {
  computed: {
    isAuthenticated() {
      return this.$store.getters.auth
    },
    isAdmin() {
      return this.$store.getters.isAdmin
    },
    ...mapGetters({
      auth: 'auth'
    })
  },
  methods: {
    currentUser() {
      if (firebase.auth().currentUser) {
        return firebase.auth().currentUser
      } else {
        return new Promise((resolve, reject) => {
          const unsubscribe = firebase.auth().onAuthStateChanged(user => {
            unsubscribe()
            resolve(user)
          }, reject)
        })
      }
    },
    async getAuthToken() {
      let user = await this.currentUser()
      if (!user) {
        return false
      }
      let token = await user.getIdTokenResult(true)
      return token
    },
    async isAdminUser() {
      let token = await this.getAuthToken()
      if (!token) {
        return false
      }
      let isAdmin = token.claims.admin ? true : false
      this.$store.commit('admin', isAdmin)
      return isAdmin
    },
    async checkAdmin() {
      let isAdmin = await this.isAdminUser()
      if (isAdmin) {
        localStorage.setItem('admin', true)
      } else {
        localStorage.removeItem('admin')
      }
    },
    async checkSolarAdmin() {
      let t = await this.getAuthToken()
      if (!t) {
        return
      }
      let solarAdmin = !!t.claims.solarAdmin
      if (solarAdmin) {
        localStorage.setItem('solarAdmin', solarAdmin)
      } else {
        localStorage.removeItem('solarAdmin')
      }
      this.$store.commit('solarAdmin', solarAdmin)
    },
    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          localStorage.removeItem('auth')
          localStorage.removeItem('admin')
          this.$store.commit('auth', false)
          window.location.href = '/login'
        })
        .catch(error => {
          alert(error.message)
          window.location.href = '/login'
        })
    },
    async updateUser(user) {
      let userData = await userRef.doc(user.uid).get()
      let data = { email: user.email, profile: {} }
      if (userData.exists) {
        data.profile = userData.data()
      }
      this.$store.commit('user', { ...data })
    },
    async updateUserDetail(user, data) {
      await userRef.doc(user).set(data)
    },
    async userProfile() {
      let user = await this.currentUser()
      let profile = await userRef.doc(user.uid).get()
      if (profile.exists) {
        return profile.data()
      } else {
        return null
      }
    },
    async changeProfileData(data, user) {
      if (!user) {
        user = await this.currentUser()
      }
      let prevData = await this.userProfile(user)
      let newData = { ...prevData }
      newData = { ...newData, ...data }
      await userRef.doc(user.uid).set(newData)
    },
    async deleteUserDoc(uid) {
      await userRef.doc(uid).delete()
    },

    async getMetrics(user) {
      if (!user) {
        user = await this.currentUser()
      }
      let metrics = {}
      let profile = await userRef.doc(user.uid).get()
      if (profile.exists) {
        metrics = profile.data().metrics
      }
      return metrics
    },
    increaseCount(value) {
      if (!value) {
        value = 0
      }
      value++
      return value
    },
    decreaseCount(value) {
      if (!value) {
        value = 0
      }
      value--
      return value
    },
    async changeMetric(metric, func, user = null) {
      if (!user) {
        user = await this.currentUser()
      }
      let metrics = await this.getMetrics(user)
      metrics[metric] = func(metrics[metric])
      await this.changeProfileData({ metrics: metrics }, user)
    },
    async reduceMetrics(data) {
      let metrics = await this.userProfile()
      metrics = metrics.metrics
      for (let e of Object.keys(data)) {
        if (data[e]) {
          metrics[e] -= data[e]
        }
      }
      this.changeProfileData({ metrics: metrics })
    },
    updateMetrics(params) {
      let defaultParams = {
        path: this.$route.path,
        parcel: this.$route.params.id
      }
      defaultParams = { ...defaultParams, ...params }
      const url = `${api.cloudFunctionAPI}/metrics/update`
      this.$axios.get(url, {
        params: defaultParams
      })
    },
    async logEvent(type, data) {
      let user = await this.currentUser()
      if (!user) {
        return
      }
      if (!data) {
        data = {}
      }
      if (typeof data != 'object') {
        data = {}
      }

      let event = { type: type, ...data }
      event.timestamp = new Date()
      event.user = user.uid
      event.path = this.$route.path

      let parcel = this.$route.path.split('/')[1]
      if (parcel == 'parcel' && type != 'navigation') {
        if (!event.parcel) {
          event.parcel = this.$route.params.id
        }
      }
      let res = await eventRef.add(event)
      return res
    },
    async logNavigation() {
      this.logEvent('navigation')
    },
    async updateLoginHistory() {
      let timezone = new Date().getTimezoneOffset()
      try {
        await this.$axios.post('/login', { timezone })
      } catch (err) {
        console.log(err)
      }
    }
  }
}
