import user from '@/mixins/user'
import { septicRef } from '@/firebase/db'
export default {
  mixins: [user],
  data: () => ({
    SEPTIC_DATA: {
      septic_systems: [{ system_information: null, data: [] }]
    },
    SEPTIC_OPTIONS: {
      installedBy: [
        'James J. Roderick',
        'JF Noons',
        'Nauset Septic',
        'Robert Waters',
        'Other'
      ],
      component_types: [
        { text: 'Title 5 Septic', value: 'title_5_septic' },
        { text: 'Tight Tank', value: 'tight_tank' },
        { text: 'I/A System', value: 'ia_system' },
        { text: 'Grease Trap', value: 'grease_trap' },
        { text: 'Cesspool', value: 'cesspool' },
        { text: 'Other', value: 'other' }
      ]
    }
  }),
  methods: {
    async getSepticData(id) {
      let res = await septicRef.doc(id).get()
      if (res.exists) {
        return { ...res.data() }
      } else {
        this.initSeptic(id)
        let data = await septicRef.doc(id).get()
        return { ...data.data() }
      }
    },
    async initSeptic(id) {
      let res = await this.saveSepticData(id, this.SEPTIC_DATA)
      return res
    },
    async saveSepticData(parcelId, data) {
      await septicRef.doc(parcelId).set(data)
    },
    getSepticComponent(septic) {
      if (septic) {
        let text = this.SEPTIC_OPTIONS.component_types.filter(
          e => e.value == septic
        )
        return text[0].text || ''
      }
      return ''
    }
  }
}
