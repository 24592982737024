<template>
  <div>
    <div>
      <v-dialog max-width="600" v-model="dialogs.share">
        <v-card class="mx-auto">
          <v-card-title>
            <v-spacer>Add Images</v-spacer>
            <v-btn icon @click="dialogs.share = false"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-card-title>
          <v-container>
            <GalleryAddForm
              :key="dialogs.share"
              :logged-in="true"
              :storage="storage"
              :categories="categories"
              :user-setting="userSetting"
              :gallery-ref="galleryRef"
              photo-required
              :user-data="userData"
              :selected-category="selectedCategory"
              :gallery="gallery"
              :fields="fields"
              :data="data"
              @cancel="dialogs.share = false"
              @success="dialogs.share = false"
            />
          </v-container>
        </v-card>
      </v-dialog>
      <div class="my-1">
        <GalleryView
          :parcel="parcel"
          :galleryRef="galleryRef"
          :category="feature"
          :show-date="gallerySetting.showDate"
          @update:count="onUpdateCount"
        />
      </div>
      <div class="text-center">
        <v-btn
          class="mr-1"
          icon
          @click="toggleDate()"
          :color="gallerySetting.showDate ? 'primary' : ''"
          ><v-icon>mdi-calendar</v-icon></v-btn
        >
        <v-btn color="primary" small @click="dialogs.share = true"
          >Add Images
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import config from '../../config'
import GalleryAddForm from './GalleryAddForm.vue'
import GalleryView from './GalleryView'
import { storage } from '@/services/storage'
import { galleryRef } from '@/services/firestore'

import { mapGetters } from 'vuex'
export default {
  props: {
    parcel: {
      type: String,
      required: true
    },
    feature: {
      type: String,
      default: 'Parcel'
    }
  },

  components: {
    GalleryAddForm,
    GalleryView
  },
  computed: {
    ...mapGetters({
      user: 'user',
      gallerySetting: 'gallerySetting'
    })
  },
  watch: {
    user: {
      handler(val) {
        this.userData.email = val.email
        this.userData.firstName = val.profile.first_name
        this.userData.lastName = val.profile.last_name
      },
      immediate: true
    }
  },
  data() {
    return {
      storage,
      dialogs: { share: false },
      categories: [],
      userSetting: { gps: true },
      galleryRef,
      userData: {
        email: '',
        firstName: '',
        lastName: ''
      },
      data: {
        parcelId: this.parcel
      },
      selectedCategory: this.feature,
      gallery: {
        name: 'Peeps',
        id: config.galleryID()
      },
      fields: ['caption', 'subcaption', 'imageUpload', 'parcel']
    }
  },
  methods: {
    onUpdateCount(count) {
      this.$emit('update:count', count)
    },
    toggleDate() {
      const value = !this.gallerySetting.showDate
      this.$store.dispatch('updateGallerySetting', {
        key: 'showDate',
        value
      })
    }
  }
}
</script>

<style></style>
