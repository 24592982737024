export default {
  state: {
    taxHistory: [],
    activeTax: -1,
    editTaxData: null
  },
  getters: {
    taxHistory(state) {
      state.taxHistory.map((e, i) => {
        e.index = i
        return e
      })
      return state.taxHistory
    },
    activeTax(state) {
      return state.activeTax
    },
    activeTaxData(state) {
      let res = null
      if (state.activeTax != -1) {
        res = state.taxHistory[state.activeTax]
      }
      return res
    },
    editTaxData(state) {
      return state.editTaxData
    }
  },
  mutations: {
    sortTaxHistory(state) {
      let data = [...state.taxHistory]
      data.sort((a, b) => parseInt(b.year) - parseInt(a.year))
      state.taxHistory = data
    },
    addTaxHistory(state, item) {
      state.taxHistory.push(item)
      this.commit('sortTaxHistory')
    },
    setTaxHistory(state, item) {
      state.taxHistory = item
      this.commit('sortTaxHistory')
    },
    // updateTaxHistory (state, { data, index }) {
    // 	state.taxHistory.splice(index, 1, data)
    // },
    updateTaxHistory(state, data) {
      state.taxHistory.splice(state.activeTax, 1, data)
    },
    removeTaxHistory(state, index) {
      state.taxHistory.splice(index, 1)
    },
    setActiveTax(state, index) {
      state.activeTax = index
    },
    setEditTaxData(state, data) {
      state.editTaxData = data
    }
  }
}
