<template>
  <div class="ma-1 mt-n4">
    <fuel-chip
      v-for="(source, index) in sources"
      class="ml-2 mb-1"
      :key="index"
      :shortName="shortName"
      :value="source"
    ></fuel-chip>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    data: {
      type: Object
    },
    shortName: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters(['HVACSources']),
    sources() {
      let s = this.copyObject(this.HVACSources)
      s = s.filter(e => {
        return this.data[e.value] && this.data[e.value].value
      })
      return s
    }
  }
}
</script>

<style></style>
