<template>
  <v-chip small dark :color="color" v-bind="$attrs" class="cursor-pointer"
    ><slot
      >{{ componentText(componentType) }}
      <v-icon class="ml-2">mdi-folder-information-outline</v-icon></slot
    ></v-chip
  >
</template>

<script>
import septic from '@/mixins/septic'
export default {
  mixins: [septic],
  props: {
    componentType: {
      type: String,
      default: ''
    }
  },
  computed: {
    profile() {
      return this.$store.getters.user.profile
    },
    color() {
      let color = this.getFeature('septic').style.color
      if (this.profile.settings && this.profile.settings.chip_color) {
        if (this.profile.settings.chip_color.septic) {
          color = this.profile.settings.chip_color.septic
        }
      }
      return color
    }
  },
  methods: {
    componentText(c) {
      let component = this.SEPTIC_OPTIONS.component_types.filter(
        e => e.value == c
      )
      if (component[0]) {
        return component[0].text
      } else {
        return c
      }
    }
  }
}
</script>

<style></style>
