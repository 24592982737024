import { render, staticRenderFns } from "./FeatureBreadcrumb.vue?vue&type=template&id=55184c36&scoped=true&"
import script from "./FeatureBreadcrumb.vue?vue&type=script&lang=js&"
export * from "./FeatureBreadcrumb.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55184c36",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsItem } from 'vuetify/lib/components/VBreadcrumbs';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VBreadcrumbs,VBreadcrumbsItem,VSelect})
