<template>
  <base-feature-button
    v-bind="$attrs"
    :color="colors.nitrate"
    width="150"
    large
    dark
    href="/explore-nitrate"
    target="_nitrates"
    class="text-capitalize"
  >
    <template v-slot:text>{{ text }}</template>
    <template v-slot:suffix>
      <v-icon> mdi-stethoscope </v-icon>
    </template></base-feature-button
  >
</template>

<script>
import BaseFeatureButton from './BaseFeatureButton'
import colors from '../../../design/_colors.scss'
export default {
  props: {
    text: {
      type: String,
      default: 'Nitrate'
    }
  },
  components: {
    BaseFeatureButton
  },
  computed: {
    colors() {
      return colors
    }
  }
}
</script>

<style></style>
