import {
  randomString
} from '../utils/random'
import {
  firestore,
  COLLECTION
} from './firestore'

const getImageId = async () => {
  let isUnique = false
  let id
  let count = 0
  while (!isUnique && (count < 100)) {
    count++
    id = randomString(6)
    const check = await firestore
      .collection(COLLECTION.gallery)
      .where('images', 'array-contains', id)
      .get()
    if (!check.docs.length) {
      isUnique = true
    }
  }
  return id
}

export {
  getImageId
}
