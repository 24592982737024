import Vue from 'vue'
import SepticSystemChip from './SepticSystemChip'
import WaterSourceChip from './WaterSourceChip'
import HvacChip from './HVACChip'
import FuelsChip from './FuelsChip'
import FuelChip from './FuelChip'

Vue.component('water-source-chip', WaterSourceChip)
Vue.component('septic-system-chip', SepticSystemChip)
Vue.component('hvac-chip', HvacChip)
Vue.component('fuels-chip', FuelsChip)
Vue.component('fuel-chip', FuelChip)
