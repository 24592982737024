<template>
  <v-card class="mx-auto">
    <v-card-title></v-card-title>
    <v-card-text>
      <slot name="message">
        Are you sure? Do you want to delete this item?
      </slot>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="error" @click="emit('delete')">Yes</v-btn
      ><v-btn color="secondary" text @click="emit('cancel')">No</v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  methods: {
    emit(val) {
      this.$emit(val)
    }
  }
}
</script>

<style></style>
