<script>
import { VProgressCircular } from 'vuetify/lib'
export default {
  extends: VProgressCircular,
  props: {
    indeterminate: {
      type: Boolean,
      default: true
    },
    width: {
      type: Number,
      default: 6
    },
    size: {
      type: Number,
      default: 50
    },
    color: {
      type: String,
      default: 'primary'
    }
  }
}
</script>

<style></style>
