import Vue from 'vue'
import VueRouter from 'vue-router'

import AppHeader from '@/components/AppHeader.vue'
import AppFooter from '@/components/AppFooter.vue'
// import admin from './admin';
import adminRoutes from './admin.router'
import aboutRoutes from './about.router'
import featureRoutes from './feature.router'

const Home = () => import(/* webpackChunkName: "Home" */ '@/views/Home')
const Login = () => import(/* webpackChunkName: "Login" */ '@/views/Login')
const Signup = () => import(/* webpackChunkName: "Signup" */ '@/views/Signup')
const Profile = () =>
  import(/* webpackChunkName: "Profile" */ '@/views/Profile')
const AccountRecovery = () =>
  import(/* webpackChunkName: "AccountRecovery" */ '@/views/AccountRecovery')
const Contact = () =>
  import(/* webpackChunkName: "Contact" */ '@/views/Contact')
const Help = () => import(/* webpackChunkName: "Help" */ '@/views/Help')
const NitrateArticle = () =>
  import(
    /* webpackChunkName: "NitrateArticle" */ '@/views/NitrateArticleDetail'
  )
const Journals = () =>
  import(/* webpackChunkName: "Journals" */ '@/views/Journals')
const Auth = () => import(/* webpackChunkName: "Auth" */ '@/views/Auth')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    components: {
      header: AppHeader,
      default: Home,
      footer: AppFooter
    }
  },
  {
    path: '/login',
    name: 'Login',
    components: {
      header: AppHeader,
      default: Login,
      footer: AppFooter
    }
  },
  {
    path: '/signup',
    name: 'Signup',
    components: {
      header: AppHeader,
      default: Signup,
      footer: AppFooter
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    components: {
      header: AppHeader,
      default: Profile,
      footer: AppFooter
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/account-recovery',
    name: 'AccountRecovery',
    components: {
      header: AppHeader,
      default: AccountRecovery,
      footer: AppFooter
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    meta: {
      requiresAuth: true
    },
    components: {
      header: AppHeader,
      default: Contact,
      footer: AppFooter
    }
  },
  {
    path: '/help',
    name: 'Help',
    components: {
      header: AppHeader,
      default: Help,
      footer: AppFooter
    }
  },
  {
    path: '/journals',
    name: 'Journals',
    components: {
      header: AppHeader,
      default: Journals,
      footer: AppFooter
    }
  },
  {
    path: '/nitrate-articles/:id',
    name: 'NitrateArticle',
    components: {
      header: AppHeader,
      default: NitrateArticle,
      footer: AppFooter
    }
  },
  {
    path: '/auth',
    name: 'Auth',
    components: {
      header: AppHeader,
      default: Auth,
      footer: AppFooter
    }
  },
  ...adminRoutes,
  ...aboutRoutes,
  ...featureRoutes
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  let auth = !!localStorage.getItem('auth')
  let admin = !!localStorage.getItem('admin')
  if (to.meta.requiresAuth) {
    if (!auth) {
      next({ path: `/login?next=${to.path}` })
    } else {
      next()
    }
  } else if (to.meta.admin) {
    if (!admin) {
      next({ path: `/login?next=${to.path}` })
    } else {
      next()
    }
  } else {
    if (auth && (to.name == 'Login' || to.name == 'Signup')) {
      next({ path: '/' })
    }
  }
  next()
})

router.onError(error => {
  if (/loading chunk \d* failed./i.test(error.message)) {
    window.location.reload()
  }
})

export default router
