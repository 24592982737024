export default {
  state: {
    waterTestHistory: [],
    waterSource: null,
    activeWater: -1
  },
  getters: {
    waterTestHistory(state) {
      state.waterTestHistory.map((e, i) => {
        e.index = i
        return e
      })
      return state.waterTestHistory
    },
    waterSource(state) {
      return state.waterSource
    },
    activeWater(state) {
      return state.activeWater
    },
    activeWaterData(state, getters) {
      return getters.waterTestHistory[state.activeWater]
    }
  },
  mutations: {
    sortWaterTestHistory(state) {
      let data = [...state.waterTestHistory]
      data.sort((a, b) => new Date(b.testDate) - new Date(a.testDate))
      state.waterTestHistory = data
    },
    addWaterTestHistory(state, item) {
      state.waterTestHistory.push(item)
      this.commit('sortWaterTestHistory')
    },
    setWaterTestHistory(state, data) {
      state.waterTestHistory = data
      this.commit('sortWaterTestHistory')
    },
    updateWaterTestHistory(state, { data, index }) {
      state.waterTestHistory.splice(index, 1, data)
      this.commit('sortWaterTestHistory')
    },
    removeWaterTestHistory(state, index) {
      state.waterTestHistory.splice(index, 1)
    },
    setWaterSource(state, data) {
      state.waterSource = data
    },
    setActiveWater(state, index) {
      state.activeWater = index
    }
  }
}
