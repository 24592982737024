import AppHeader from '@/components/AppHeader'
import AppFooter from '@/components/AppFooter'

const About = () =>
  import(/* webpackChunkName: "About" */ '@/views/About/Index')
const AboutTax = () =>
  import(/* webpackChunkName: "AboutTax" */ '@/views/About/Tax')
const AboutWater = () =>
  import(/* webpackChunkName: "AboutWater" */ '@/views/About/Water')
const AboutSeptic = () =>
  import(/* webpackChunkName: "AboutSeptic" */ '@/views/About/Septic')
const AboutPeeps = () =>
  import(/* webpackChunkName: "AboutPeeps" */ '@/views/About/Peeps')
const AboutPrivacy = () =>
  import(/* webpackChunkName: "AboutPrivacy" */ '@/views/About/Privacy')
const AboutHVAC = () =>
  import(/* webpackChunkName: "AboutHVAC" */ '@/views/About/HVAC')
const AboutSolar = () =>
  import(/* webpackChunkName: "AboutSolar" */ '@/views/About/Solar')
const ExploreNitrate = () =>
  import(/* webpackChunkName: "ExploreNitrate" */ '@/views/ExploreNitrate')

const Features = () =>
  import(/* webpackChunkName: "Features" */ '@/views/Features')

const routes = [
  {
    path: '/about',
    name: 'About',
    components: {
      header: AppHeader,
      default: About,
      footer: AppFooter
    }
  },
  {
    path: '/about/peeps',
    name: 'FeaturePeeps',
    components: {
      header: AppHeader,
      default: AboutPeeps,
      footer: AppFooter
    }
  },

  {
    path: '/features',
    name: 'Features',
    components: {
      header: AppHeader,
      default: Features,
      footer: AppFooter
    },
    children: [
      {
        path: '/features/tax',
        name: 'FeatureTax',
        components: {
          header: AppHeader,
          default: AboutTax,
          footer: AppFooter
        }
      },
      {
        path: '/features/hvac',
        name: 'FeatureHVAC',
        components: {
          header: AppHeader,
          default: AboutHVAC,
          footer: AppFooter
        }
      },
      {
        path: '/features/water',
        name: 'FeatureWater',
        components: {
          header: AppHeader,
          default: AboutWater,
          footer: AppFooter
        }
      },

      {
        path: '/features/septic',
        name: 'FeatureSeptic',
        components: {
          header: AppHeader,
          default: AboutSeptic,
          footer: AppFooter
        }
      },
      {
        path: '/features/solar',
        name: 'AboutSolar',
        components: {
          header: AppHeader,
          default: AboutSolar,
          footer: AppFooter
        }
      }
    ]
  },

  {
    path: '/about/privacy',
    name: 'AboutPrivacy',
    components: {
      header: AppHeader,
      default: AboutPrivacy,
      footer: AppFooter
    }
  },
  {
    path: '/explore-nitrate',
    name: 'ExploreNitrate',
    components: {
      header: AppHeader,
      default: ExploreNitrate,
      footer: AppFooter
    }
  },
  { path: '/nitrate', redirect: '/explore-nitrate' },
  { path: '/nitrates', redirect: '/explore-nitrate' }
];
export default routes;
