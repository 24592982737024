import { userRef } from '@/firebase/db'
export default {
  methods: {
    async watchAll() {
      let user = await this.currentUser()
      if (user) {
        this.watchProfile()
      }
    },
    async watchProfile() {
      let user = await this.currentUser()
      if (user) {
        userRef.doc(user.uid).onSnapshot(data => {
          const userData = {
            profile: { ...data.data() },
            email: user.email,
            uid: user.uid
          }

          this.$store.commit('user', userData)
          if (userData.profile.peepsGallerySetting) {
            this.$store.dispatch("updateGallerySettingObj", userData.profile.peepsGallerySetting)
          }
        })
        this.changeProfileData({ last_login: new Date() })
      }
    }
  }
}
