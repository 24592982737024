<template>
  <v-img v-bind="$attrs" class="grey lighten-3" @click="$emit('click')">
    <template #placeholder>
      <v-row class="fill-height ma-0" align="center" justify="center">
        <v-progress-circular
          indeterminate
          color="grey lighten-2"
        ></v-progress-circular>
      </v-row>
    </template>
    <template #default>
      <slot name="default"></slot>
    </template>
  </v-img>
</template>

<script>
export default {}
</script>

<style></style>
