import AppHeader from '@/components/AppHeader'
import AppFooter from '@/components/AppFooter'

const Parcel = () =>
  import(/* webpackChunkName: "Parcel" */ '@/views/Parcel/Index')
const ParcelIntro = () =>
  import(/* webpackChunkName: "ParcelIntro" */ '@/views/Parcel/ParcelIntro')
const Tax = () =>
  import(/* webpackChunkName: "Tax" */ '@/views/Parcel/Tax/Index')
const Water = () =>
  import(/* webpackChunkName: "Water" */ '@/views/Parcel/Water/Index')
const Septic = () =>
  import(/* webpackChunkName: "Septic" */ '@/views/Parcel/Septic/Index')
const HVAC = () =>
  import(/* webpackChunkName: "HVAC" */ '@/views/Parcel/HVAC/Index')
const Solar = () =>
  import(/* webpackChunkName: "Solar" */ '@/views/Parcel/Solar/Index')

const routes = [
  {
    path: '/parcels',
    name: 'Parcel',
    components: {
      header: AppHeader,
      default: Parcel,
      footer: AppFooter
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/parcel-intro',
    name: 'ParcelIntro',
    components: {
      header: AppHeader,
      default: ParcelIntro,
      footer: AppFooter
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/parcels/:id/taxes',
    name: 'Tax',
    components: {
      header: AppHeader,
      default: Tax,
      footer: AppFooter
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/parcels/:id/hvac',
    name: 'HVAC',
    components: {
      header: AppHeader,
      default: HVAC,
      footer: AppFooter
    },
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/parcels/:id/water',
    name: 'Water',
    meta: {
      requiresAuth: true
    },
    components: {
      header: AppHeader,
      default: Water,
      footer: AppFooter
    }
  },
  {
    path: '/parcels/:id/septic',
    name: 'Septic',
    meta: {
      requiresAuth: true
    },
    components: {
      header: AppHeader,
      default: Septic,
      footer: AppFooter
    }
  },
  {
    path: '/parcels/:id/solar',
    name: 'Solar',
    meta: {
      requiresAuth: true
    },
    components: {
      header: AppHeader,
      default: Solar,
      footer: AppFooter
    }
  }
]
export default routes
