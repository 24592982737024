export default {
  state: {
    userList: [],
    taxCpiGrowth: {
      data: {
        data: []
      },
      processing: false
    },
    messages: []
  },
  getters: {
    userList(state) {
      return state.userList
    },
    taxCpiGrowth(state) {
      return state.taxCpiGrowth
    },
    taxCpiGrowthData(state) {
      state.taxCpiGrowth.data.data.map((e, i) => {
        e.index = i
      })
      return state.taxCpiGrowth.data
    },
    messages(state) {
      return state.messages
    }
  },
  mutations: {
    userList(state, data) {
      state.userList = data
    },
    setTaxCpiGrowth(state, { data }) {
      state.taxCpiGrowth = data
    },
    setTaxCpiGrowthByKey(state, { key, value }) {
      state.taxCpiGrowth[key] = value
    },
    setMessages(state, data) {
      state.messages = data
    }
  },
  actions: {}
}
