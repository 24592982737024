<template>
  <v-app>
    <router-view name="header" />
    <router-view :key="$route.fullPath" />
    <router-view name="footer" />
  </v-app>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import watcher from '@/mixins/watcher'
import config from './config'
export default {
  mixins: [watcher],
  name: 'App',

  metaInfo: {
    title: 'Peeps Portal',
    titleTemplate: '%s - Peeps Portal'
  },
  async beforeMount() {
    let token = localStorage.getItem('token')
    if (token) {
      this.$axios.defaults.headers.common['authorization'] = `Bearer ${token}`
    }

    firebase.auth().onIdTokenChanged(async token => {
      if (token) {
        token = await token.getIdToken()
        localStorage.setItem('token', token)
        this.$axios.defaults.headers.common['authorization'] = `Bearer ${token}`
      }
    })

    firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        this.updateLoginHistory()
        this.$store.commit('auth', true)
        this.checkAdmin()
        this.checkSolarAdmin()
        this.watchAll()
        if (this.$store.state.redirect) {
          if (this.$route.path == '/login' || this.$route.path == '/signup') {
            let next = this.$route.query.next || '/'
            this.$router.push(next)
          }
        }
      } else {
        this.$store.commit('auth', false)
        if (this.$route.meta.requiresAuth) {
          this.$router.push('/login')
        }
      }
    })
  },
  mounted() {
    this.changeFavicon()
  },
  methods: {
    changeFavicon() {
      if (config.deployment === 'dev') {
        this.setFavicon('/favicon-dev.ico')
      } else if (config.deployment === 'beta') {
        this.setFavicon('/favicon-beta.ico')
      }
    }
  },
  watch: {
    $route() {
      this.logNavigation()
    }
  }
}
</script>

<style></style>
