import firebase from 'firebase/app'
import 'firebase/firestore'
import './firebase'

const firestore = firebase.firestore()

const COLLECTION = {
  comment: 'news_comments',
  gallery_name: 'news_gallery_names', // galleries
  gallery_type: 'news_gallery_types', // categories
  gallery: 'news_galleries', // posts
  gallery_comment: 'news_gallery_comments',
  gallery_like: 'news_gallery_likes',
  gallery_view: 'news_gallery_views',
  gallery_permission: 'news_gallery_permissions',
  message: 'news_messages',
  newsletter_archive: 'news_newsletter_archives',
  user: 'users',
  parcel: 'parcels',
  file: 'files'
}

// const commentRef = firestore.ref(COLLECTION.comment)
const galleryRef = firestore.collection(COLLECTION.gallery)
const parcelRef = firestore.collection(COLLECTION.parcel);
const fileRef = firestore.collection(COLLECTION.file)
// const galleryTypeRef = firestore.ref(COLLECTION.gallery_type)
const galleryCommentRef = firestore.collection(COLLECTION.gallery_comment)
// const galleryLikeRef = firestore.ref(COLLECTION.gallery_like)
// const galleryViewRef = firestore.ref(COLLECTION.gallery_view)
// const messageRef = firestore.ref(COLLECTION.message)
// const newsletterArchiveRef = firestore.ref(COLLECTION.newsletter_archive)
const userRef = firestore.collection(COLLECTION.user)
export {
  firestore,
  COLLECTION,

  galleryRef,
  galleryCommentRef,
  userRef,
  parcelRef,
  fileRef
}
