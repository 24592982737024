<template>
  <span @click="$emit('click', value)" @mouseenter="$emit('click', value)">
    <svg
      v-if="value == 'water'"
      :class="getClass('water')"
      xmlns:dc="http://purl.org/dc/elements/1.1/"
      xmlns:cc="http://creativecommons.org/ns#"
      xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
      xmlns:svg="http://www.w3.org/2000/svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
      xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
      viewBox="0 0 500 500"
      width="100%"
      :style="{ 'max-width': maxWidth('water') }"
      id="blobSvg"
      version="1.1"
      sodipodi:docname="blob3.svg"
      inkscape:version="1.0.1 (3bc2e813f5, 2020-09-07)"
    >
      <metadata id="metadata100">
        <rdf:RDF>
          <cc:Work rdf:about="">
            <dc:format>image/svg+xml</dc:format>
            <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
          </cc:Work>
        </rdf:RDF>
      </metadata>
      <defs id="defs98" />
      <sodipodi:namedview
        pagecolor="#ffffff"
        bordercolor="#666666"
        borderopacity="1"
        objecttolerance="10"
        gridtolerance="10"
        guidetolerance="10"
        inkscape:pageopacity="0"
        inkscape:pageshadow="2"
        inkscape:window-width="1366"
        inkscape:window-height="704"
        id="namedview96"
        showgrid="false"
        inkscape:zoom="1.1"
        inkscape:cx="250"
        inkscape:cy="250"
        inkscape:window-x="0"
        inkscape:window-y="27"
        inkscape:window-maximized="1"
        inkscape:current-layer="blobSvg"
      />
      <path
        id="blob"
        d="M419,335.5Q349,421,242.5,434Q136,447,85,348.5Q34,250,96,170.5Q158,91,252,87.5Q346,84,417.5,167Q489,250,419,335.5Z"
      />
      <text
        xml:space="preserve"
        :style="
          `
          font-style: normal;
          font-weight: normal;
          font-size: 85.9754px;
          line-height: 1.25;
          font-family: sans-serif;
          fill: white;
          fill-opacity: 1;
          stroke: none;
          stroke-width: 2.14938;`
        "
        x="123.78516"
        y="286.73132"
        id="text128"
      >
        <tspan
          sodipodi:role="line"
          id="tspan126"
          x="123.78516"
          y="286.73132"
          style="stroke-width: 2.14938; fill: white"
        >
          Water
        </tspan>
      </text>
    </svg>

    <svg
      :class="getClass('tax')"
      v-if="value == 'tax'"
      xmlns:dc="http://purl.org/dc/elements/1.1/"
      xmlns:cc="http://creativecommons.org/ns#"
      xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
      xmlns:svg="http://www.w3.org/2000/svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
      xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
      viewBox="0 0 500 500"
      width="100%"
      :style="{ 'max-width': maxWidth('tax') }"
      id="blobSvg"
      version="1.1"
      sodipodi:docname="BlobTax.svg"
      inkscape:version="1.0.1 (3bc2e813f5, 2020-09-07)"
    >
      <metadata id="metadata108">
        <rdf:RDF>
          <cc:Work rdf:about="">
            <dc:format>image/svg+xml</dc:format>
            <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
          </cc:Work>
        </rdf:RDF>
      </metadata>
      <defs id="defs106" />
      <sodipodi:namedview
        pagecolor="#ffffff"
        bordercolor="#666666"
        borderopacity="1"
        objecttolerance="10"
        gridtolerance="10"
        guidetolerance="10"
        inkscape:pageopacity="0"
        inkscape:pageshadow="2"
        inkscape:window-width="1366"
        inkscape:window-height="704"
        id="namedview104"
        showgrid="false"
        inkscape:zoom="1.1"
        inkscape:cx="250"
        inkscape:cy="250"
        inkscape:window-x="0"
        inkscape:window-y="27"
        inkscape:window-maximized="1"
        inkscape:current-layer="blobSvg"
        inkscape:document-rotation="0"
      />
      <path
        id="blob"
        d="M383.5,341.5Q356,433,247,438.5Q138,444,79.5,347Q21,250,87,165.5Q153,81,255.5,72Q358,63,384.5,156.5Q411,250,383.5,341.5Z"
      />
      <text
        xml:space="preserve"
        style="
          font-style: normal;
          font-weight: normal;
          font-size: 110.257px;
          line-height: 1.25;
          font-family: sans-serif;
          fill: #ffffff;
          fill-opacity: 1;
          stroke: none;
          stroke-width: 2.75644;
        "
        x="66.006645"
        y="300.43372"
        id="text124"
      >
        <tspan
          sodipodi:role="line"
          id="tspan122"
          x="66.006645"
          y="300.43372"
          style="fill: #ffffff; stroke-width: 2.75644"
        >
          Taxes
        </tspan>
      </text>
    </svg>

    <svg
      v-if="value == 'hvac'"
      :class="getClass('hvac')"
      :style="{ 'max-width': maxWidth('hvac') }"
      xmlns:dc="http://purl.org/dc/elements/1.1/"
      xmlns:cc="http://creativecommons.org/ns#"
      xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
      xmlns:svg="http://www.w3.org/2000/svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
      xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
      viewBox="0 0 500 500"
      width="100%"
      id="blobSvg"
      version="1.1"
      sodipodi:docname="BlobHVAC.svg"
      inkscape:version="1.0.1 (3bc2e813f5, 2020-09-07)"
    >
      <metadata id="metadata84">
        <rdf:RDF>
          <cc:Work rdf:about="">
            <dc:format>image/svg+xml</dc:format>
            <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
          </cc:Work>
        </rdf:RDF>
      </metadata>
      <defs id="defs82" />
      <sodipodi:namedview
        pagecolor="#ffffff"
        bordercolor="#666666"
        borderopacity="1"
        objecttolerance="10"
        gridtolerance="10"
        guidetolerance="10"
        inkscape:pageopacity="0"
        inkscape:pageshadow="2"
        inkscape:window-width="1366"
        inkscape:window-height="704"
        id="namedview80"
        showgrid="false"
        inkscape:zoom="1.026"
        inkscape:cx="250"
        inkscape:cy="250"
        inkscape:window-x="0"
        inkscape:window-y="27"
        inkscape:window-maximized="1"
        inkscape:current-layer="blobSvg"
        inkscape:document-rotation="0"
      />
      <path
        id="blob"
        d="M383,347Q362,444,251,442Q140,440,89.5,345Q39,250,99,171Q159,92,244.5,101.5Q330,111,367,180.5Q404,250,383,347Z"
      />
      <text
        xml:space="preserve"
        style="
          font-style: normal;
          font-weight: normal;
          font-size: 81.4481px;
          line-height: 1.25;
          font-family: sans-serif;
          fill: white;
          fill-opacity: 1;
          stroke: none;
          stroke-width: 2.03621;
        "
        x="85.330009"
        y="303.88812"
        id="text136"
      >
        <tspan
          sodipodi:role="line"
          id="tspan134"
          x="85.330009"
          y="303.88812"
          style="stroke-width: 2.03621; fill:white"
        >
          Energy
        </tspan>
      </text>
    </svg>

    <svg
      v-if="value == 'solar'"
      :class="getClass('solar')"
      xmlns:dc="http://purl.org/dc/elements/1.1/"
      xmlns:cc="http://creativecommons.org/ns#"
      xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
      xmlns:svg="http://www.w3.org/2000/svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
      xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
      viewBox="0 0 500 500"
      width="100%"
      :style="{ 'max-width': maxWidth('solar') }"
      id="blobSvg"
      version="1.1"
      sodipodi:docname="blob1.svg"
      inkscape:version="1.0.1 (3bc2e813f5, 2020-09-07)"
    >
      <metadata id="metadata116">
        <rdf:RDF>
          <cc:Work rdf:about="">
            <dc:format>image/svg+xml</dc:format>
            <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
          </cc:Work>
        </rdf:RDF>
      </metadata>
      <defs id="defs114" />
      <sodipodi:namedview
        pagecolor="#ffffff"
        bordercolor="#666666"
        borderopacity="1"
        objecttolerance="10"
        gridtolerance="10"
        guidetolerance="10"
        inkscape:pageopacity="0"
        inkscape:pageshadow="2"
        inkscape:window-width="1366"
        inkscape:window-height="704"
        id="namedview112"
        showgrid="false"
        inkscape:zoom="1.1"
        inkscape:cx="250"
        inkscape:cy="250"
        inkscape:window-x="0"
        inkscape:window-y="27"
        inkscape:window-maximized="1"
        inkscape:current-layer="blobSvg"
      />
      <path
        id="blob"
        d="M383.5,326Q338,402,234.5,429Q131,456,90,353Q49,250,88.5,144.5Q128,39,230,73.5Q332,108,380.5,179Q429,250,383.5,326Z"
      />
      <text
        xml:space="preserve"
        style="
          font-style: normal;
          font-weight: normal;
          font-size: 96.4542px;
          line-height: 1.25;
          font-family: sans-serif;
          fill: white;
          fill-opacity: 1;
          stroke: none;
          stroke-width: 2.41136;
        "
        x="105.24855"
        y="284.9198"
        id="text120"
      >
        <tspan
          sodipodi:role="line"
          id="tspan118"
          x="105.24855"
          y="284.9198"
          style="fill: white; stroke-width: 2.41136"
        >
          Solar
        </tspan>
      </text>
    </svg>

    <svg
      v-if="value == 'septic'"
      :class="getClass('septic')"
      xmlns:dc="http://purl.org/dc/elements/1.1/"
      xmlns:cc="http://creativecommons.org/ns#"
      xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
      xmlns:svg="http://www.w3.org/2000/svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
      xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
      viewBox="0 0 500 500"
      width="100%"
      :style="{ 'max-width': maxWidth('septic') }"
      id="blobSvg"
      version="1.1"
      sodipodi:docname="blob4.svg"
      inkscape:version="1.0.1 (3bc2e813f5, 2020-09-07)"
    >
      <metadata id="metadata92">
        <rdf:RDF>
          <cc:Work rdf:about="">
            <dc:format>image/svg+xml</dc:format>
            <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
          </cc:Work>
        </rdf:RDF>
      </metadata>
      <defs id="defs90" />
      <sodipodi:namedview
        pagecolor="#ffffff"
        bordercolor="#666666"
        borderopacity="1"
        objecttolerance="10"
        gridtolerance="10"
        guidetolerance="10"
        inkscape:pageopacity="0"
        inkscape:pageshadow="2"
        inkscape:window-width="1366"
        inkscape:window-height="704"
        id="namedview88"
        showgrid="false"
        inkscape:zoom="1.1"
        inkscape:cx="250"
        inkscape:cy="250"
        inkscape:window-x="0"
        inkscape:window-y="27"
        inkscape:window-maximized="1"
        inkscape:current-layer="blobSvg"
      />
      <path
        id="blob"
        d="M391,321Q332,392,235,418Q138,444,100.5,347Q63,250,105,160.5Q147,71,248.5,74Q350,77,400,163.5Q450,250,391,321Z"
      />
      <text
        xml:space="preserve"
        style="
          font-style: normal;
          font-weight: normal;
          font-size: 87.1448px;
          line-height: 1.25;
          font-family: sans-serif;
          fill: #white;
          fill-opacity: 1;
          stroke: none;
          stroke-width: 2.17862;
        "
        x="111.38631"
        y="274.33975"
        id="text132"
      >
        <tspan
          sodipodi:role="line"
          id="tspan130"
          x="111.38631"
          y="274.33975"
          style="stroke-width: 2.17862; fill:white"
        >
          Septic
        </tspan>
      </text>
    </svg>
  </span>
</template>

<script>
export default {
  props: {
    value: {
      type: String
    },
    active: {
      type: String
    },
    width: {
      type: String,
      default: '150px'
    },
    widthSmall: {
      type: String,
      default: '100px'
    }
  },
  methods: {
    maxWidth() {
      let width = this.width
      if (this.$vuetify.breakpoint.width < 800) {
        this.widthSmall
      }
      return width
    },
    getClass(feature) {
      let c = ''
      if (this.active == feature) {
        c = `active active--${feature}`
      } else {
        c = `inactive inactive--${feature}`
      }
      c += ` ${feature}`
      return c
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../../design/_colors.scss"

$drop-shadow-color : #A8b3b5

$features : ("water" : $color-feature-water, "tax" : $color-feature-tax,"septic" : $color-feature-septic, "solar" : $color-feature-solar, "hvac" : $color-feature-hvac)

$text-default-colors : ("water" : #B3E5FC, "tax" : #E1BEE7, "septic" : #CFD8DC, "solar" : #78909C, "hvac" : #C8E6C9)
$text-active-colors : ("water" : #ffff, "tax" : #ffff, "septic" : #ffff, "solar" : #3f51b5, "hvac" : #ffff)

.active
  filter: drop-shadow(20px 10px 8px $drop-shadow-color)

@each $feature, $color in $text-default-colors
  .inactive--#{$feature} text tspan
    fill: $color !important

@each $feature, $color in $text-active-colors
  .active--#{$feature} text tspan
    fill: $color !important


.inactive
  opacity: 0.8

@each $f, $color in $features
  .#{$f}
    path
      fill: $color
</style>
