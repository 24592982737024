<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <span v-on="on">
        <delete-icon></delete-icon>
      </span>
    </template>
    <span>{{ message }}</span>
  </v-tooltip>
</template>

<script>
import DeleteIcon from './Delete'
export default {
  components: {
    DeleteIcon
  },
  props: {
    message: {
      type: String,
      default: 'Delete'
    }
  }
}
</script>

<style></style>
