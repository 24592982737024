<script>
import { VDataTable } from 'vuetify/lib'
export default {
  extends: VDataTable,
  props: {
    'footer-props': {
      type: Object,
      default: () => {
        return {
          itemsPerPageOptions: [10, 20, 50, -1]
        }
      }
    },
    itemsPerPage: {
      type: Number,
      default: 20
    }
  }
}
</script>
