<template>
  <v-card color="img-card">
    <div v-if="detail">
      <div style="position: relative">
        <ImageViewer
          v-model="activeImageIndex"
          :images="detail.files"
          url-key="url"
        ></ImageViewer>
        <v-container class="card-container">
          <div class="">
            <div class="text-center">
              <div
                v-if="detail.caption"
                class="pb-2 mx-2 text-h5"
                v-html="detail.caption"
              ></div>
              <div
                v-if="detail.subCaption"
                class="pb-2 light-black--text"
                v-html="detail.subCaption"
              ></div>
            </div>
            <div>
              <div>
                <div class="text-right">
                  <div class="ml-3">
                    <v-menu
                      v-model="dialogs.menu"
                      :close-on-content-click="false"
                      transition="slide-y-transition"
                    >
                      <template #activator="{ on }">
                        <span class="info-btn" v-on="on">
                          <v-tooltip bottom color="primary">
                            <template #activator="{ on }">
                              <v-icon color="grey" v-on="on"
                                >mdi-information-outline</v-icon
                              >
                            </template>
                            <span>Image Information</span>
                          </v-tooltip>
                        </span>
                      </template>
                      <PostInfo
                        :galleryRef="galleryRef"
                        :loggedIn="true"
                        :gallery="detail"
                        :active-image="activeImageIndex"
                        @close="dialogs.menu = false"
                        @updated="onGalleryUpdate"
                        @delete="onGalleryDelete"
                      ></PostInfo>
                    </v-menu>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-container>
      </div>
    </div>
    <div v-else>
      <v-skeleton-loader type="image"></v-skeleton-loader>
      <div class="d-flex justify-center">
        <v-skeleton-loader type="chip" class="mt-3"></v-skeleton-loader>
      </div>
      <div class="d-flex justify-center">
        <v-skeleton-loader
          type="text"
          width="250px"
          class="mt-3"
        ></v-skeleton-loader>
      </div>
      <div class="d-flex justify-center">
        <v-skeleton-loader
          type="text"
          width="150px"
          class="mt-3"
        ></v-skeleton-loader>
      </div>
    </div>
  </v-card>
</template>

<script>
import _ from 'lodash'
import ImageViewer from './ImageViewer.vue'
import PostInfo from './PostInfo.vue'
export default {
  components: {
    ImageViewer,
    PostInfo
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    defaultData: {
      type: Object,
      default: null
    },
    activeImage: {
      type: Number,
      default: 0
    },
    categories: {
      type: Array,
      default: () => {
        return []
      }
    },
    galleryRef: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialogs: {
        menu: false
      },
      commentForm: false,
      activeImageIndex: 0,
      detail: null,
      indexUpdated: false,
      galleryFeature: {
        like: false,
        comment: false
      }
    }
  },
  watch: {
    id: {
      handler() {
        this.getData()
      },
      immediate: true
    }
  },
  methods: {
    async getData() {
      if (this.defaultData) {
        this.detail = { ...this.defaultData, id: this.id }
      } else {
        await this.galleryRef
          .doc(this.id)
          .get()
          .then(res => {
            if (res.exists) {
              this.detail = { ...res.data(), id: this.id }
            }
          })
      }
    },
    async onGalleryUpdate() {
      try {
        const data = await this.galleryRef.doc(this.id).get()
        if (data.exists) {
          this.detail = { ...data.data(), id: data.id }
        }
      } catch (error) {
        console.error(error)
      }
    },
    onGalleryDelete() {
      this.dialogs.menu = false
      this.$emit('close')
    },
    getCategoryText(val) {
      const f = this.categories.filter(i => i.value === val)
      if (f[0]) {
        return f[0].abbr || f[0].text
      }
      return this.capitalize(val)
    },
    capitalize(str) {
      return _.capitalize(str)
    }
  }
}
</script>

<style scoped lang="scss"></style>
