<script>
import BaseForm from './GalleryBaseForm.vue'
export default {
  extends: BaseForm,
  props: {
    data: {
      type: Object,
      required: true
    },
    userData: {
      type: Object,
      required: false,
      default: null
    },
    label: {
      type: String,
      default: 'Update'
    },
    update: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    parseTimestamp(ts) {
      let date = null
      if (ts && typeof ts.getMonth === 'function') {
        date = new Date(ts)
      } else if (typeof ts === 'object') {
        date = new Date(ts.seconds * 1000)
      } else {
        date = new Date(ts)
      }
      if (date === 'Invalid Date') {
        return ''
      }
      return date
    },
    async submit() {
      this.loading = true

      let type
      if (typeof this.formData.type === 'object') {
        type = this.formData.type.value
      } else {
        type = this.formData.type
      }

      const data = {
        ...this.formData,
        type,
        updatedAt: new Date(),
        createdAt: this.parseTimestamp(this.formData.createdAt)
      }
      const id = data.id
      delete data.id

      if (data.files && data.files.length) {
        data.files.map(e => {
          delete e.file
          return e
        })
      }

      try {
        await this.galleryRef.doc(id).set(data)
        this.success = 'Gallery updated successfully'
        this.clearAlert()
        this.$emit('success')
      } catch (err) {
        this.error = 'Oops! Something went wrong!'
        this.clearAlert()
        this.$emit('error', err)
      }

      this.loading = false
      this.$emit('submit', this)
    },
    onGallerySold(val) {
      if (val) {
        if (!this.data) {
          return
        }
        if (this.data && !this.data.sold) {
          this.formData.soldAt = new Date()
        }
      }
    }
  }
}
</script>

<style></style>
