import Edit from './Edit'
import Delete from './Delete'
import EditTooltip from './EditTooltip'
import DeleteTooltip from './DeleteTooltip'
import BaseAction from './BaseAction'

import Vue from 'vue'

Vue.component('base-action', BaseAction)
Vue.component('base-edit-icon', Edit)
Vue.component('base-delete-icon', Delete)
Vue.component('base-edit-tooltip', EditTooltip)
Vue.component('base-delete-tooltip', DeleteTooltip)
