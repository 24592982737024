<template>
  <div :class="display">
    <v-dialog v-model="dialog" max-width="700">
      <v-card class="mx-auto">
        <v-card-title>
          Need a free document scanner? <v-spacer></v-spacer>
          <v-btn icon @click="dialog = !dialog"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <p>
            Use your smartphone to scan any document, like a {{ doc }}, save it
            on your computer or email it to yourself, and then you can store it
            in your secure Peeps File archive along with saving your
            {{ docNumber }}.
          </p>
          <div class="d-flex justify-center my-3">
            <div>
              <a href="https://swiftscan.app" target="_blank">
                <v-img
                  class="cursor-pointer"
                  max-width="50"
                  src="@/assets/img/SwiftScan.png"
                ></v-img>
              </a>
            </div>
            <div style="max-width: 250px; margin-left: 1rem">
              Click this scanner icon to learn more about this free scanning app
            </div>
          </div>
          <div class="d-flex">
            <div>
              <v-img src="@/assets/img/PeepsBeside.svg" max-width="35"></v-img>
            </div>
            <div>
              <p class="peeps--font ml-1">
                Note: there are other scanning apps and alternatives on the
                market, but my technical team has had good success with this
                app, and thought sharing that with you might be helpful. If you
                learn of something better, please let me know by posting a
                Comment.
              </p>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div class="">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            :icon="icon"
            dark
            large
            color="red"
            @click="dialog = !dialog"
            class="text-capitalize"
          >
            <span class="mr-2" v-if="!icon"> Scanner </span>
            <v-icon>
              mdi-scan-helper
              <slot name="iconOption"></slot
            ></v-icon>
          </v-btn>
        </template>
        <span>Free app for easy scanning of paper documents</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    doc: {
      type: String,
      default: ''
    },
    docNumber: {
      type: String,
      default: ''
    },
    icon: {
      type: Boolean,
      default: true
    },
    display: {
      type: String,
      defaut: 'd-inline'
    }
  },
  data: () => ({
    dialog: false
  })
}
</script>

<style></style>
