import Vue from 'vue'
import Vuex from 'vuex'

import parcel from './modules/parcel'
import water from './modules/water'
import address from './modules/address'
import admin from './modules/admin'
import septic from './modules/septic'
import feature from './modules/feature'
import hvac from './modules/hvac'
import tax from './modules/tax'
import solar from './modules/solar'
import styleModule from './modules/style'
import selection from './modules/selection'
import _ from 'lodash'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    parcel,
    water,
    address,
    septic,
    feature,
    hvac,
    tax,
    admin: {
      namespaced: true,
      ...admin
    },
    solar: {
      namespaced: true,
      ...solar
    },
    style: {
      namespaced: true,
      ...styleModule
    },
    selection: {
      namespaced: true,
      ...selection
    }
  },

  state: {
    auth: !!localStorage.getItem('auth'),
    isAdmin: !!localStorage.getItem('admin'),
    isSolarAdmin: !!localStorage.getItem('solarAdmin'),
    user: {
      email: '',
      displayName: '',
      uid: '',
      profile: {}
    },
    features: [],
    cpi: [],
    documents: [],
    documentFilter: '',
    teams: null,
    redirect: true
  },
  getters: {
    auth(state) {
      return state.auth
    },
    isAdmin(state) {
      return state.isAdmin
    },
    isSolarAdmin(state) {
      return state.isSolarAdmin
    },
    user(state) {
      return state.user
    },
    features(state) {
      return state.features
    },
    cpi(state) {
      return state.cpi
    },
    documents(state) {
      let docs = [...state.documents]
      if (state.documentFilter == 'na') {
        docs = docs.filter(file => !file.feature)
      } else if (state.documentFilter) {
        docs = docs.filter(file => _.lowerCase(file.feature) == _.lowerCase(state.documentFilter))
      }
      return docs
    },
    documentFilter(state) {
      return state.documentFilter
    },
    teams(state) {
      return state.teams
    }
  },
  mutations: {
    auth(state, data) {
      if (data) {
        localStorage.setItem('auth', data)
      } else {
        localStorage.removeItem('auth')
      }
      state.auth = data
    },
    admin(state, data) {
      state.isAdmin = data
    },
    solarAdmin(state, data) {
      state.solarAdmin = data
    },
    user(state, data) {
      state.user = data
    },
    updateUserByKey(state, { key, value }) {
      state.user[key] = value
    },
    userProfileKey(state, { key, value }) {
      state.user.profile[key] = value
    },
    features(state, data) {
      state.features = data
    },
    cpi(state, data) {
      state.cpi = data
    },
    documents(state, data) {
      state.documents = data
    },
    teams(state, data) {
      state.teams = data
    },
    userFeatures(state, data) {
      state.user.profile.features = data
    },
    setDocumentFilter(state, value) {
      state.documentFilter = value
    },
    redirect(state, value) {
      state.redirect = value
    }
  },
  actions: {}
})
