import Vue from 'vue'
import FeatureButton from './FeatureButton'
import NitrateButton from './NitrateButton'
import PrivacyButton from './PrivacyButton'
import PeepsButton from './PeepsButton'
import FeatureIcon from './FeatureIcon'

Vue.component('feature-button', FeatureButton)
Vue.component('nitrate-button', NitrateButton)
Vue.component('privacy-button', PrivacyButton)
Vue.component('peeps-button', PeepsButton)
Vue.component('feature-icon', FeatureIcon)
