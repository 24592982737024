<template>
  <v-menu>
    <template v-slot:activator="{ on }">
      <span v-on="on">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" :color="iconColor">{{ getIcon() }}</v-icon>
          </template>
          <span>{{ tooltipLabel }}</span>
        </v-tooltip>
      </span>
    </template>
    <base-table-filter
      v-bind="$attrs"
      :value="value"
      :clearValue="clearValue"
      @input="emit"
    ></base-table-filter>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: {
      type: String
    },
    clearValue: {
      type: String,
      default: ''
    },
    iconColor: {
      type: String,
      default: ''
    },
    tooltipLabel: {
      type: String,
      default: 'Filter Rows'
    }
  },
  methods: {
    getIcon() {
      if (this.value == this.clearValue) {
        return 'mdi-filter-off'
      } else {
        return 'mdi-filter'
      }
    },
    emit(val) {
      this.$emit('input', val)
    }
  }
}
</script>

<style></style>
