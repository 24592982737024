<template>
  <span v-if="show">
    <v-icon color="grey" class="icon">{{ icon }}</v-icon>
  </span>
</template>

<script>
export default {
  data() {
    return {
      iconList: [
        { screen: 'xs', icon: 'mdi-cellphone' },
        { screen: 'sm', icon: 'mdi-cellphone mdi-rotate-90' },
        { screen: 'md', icon: 'mdi-tablet' },
        { screen: 'lg', icon: 'mdi-monitor' },
        { screen: 'xl', icon: 'mdi-monitor' }
      ]
    }
  },
  computed: {
    show() {
      return this.$store.getters.user.profile.showScreenSize
    },
    icon() {
      let screen = this.$vuetify.breakpoint.name
      let icon = this.iconList.filter(e => e.screen == screen)[0]
      if (icon) {
        icon = icon.icon
      } else {
        icon = ''
      }
      return icon
    }
  }
}
</script>

<style scoped>
.icon {
  margin-top: 5px;
}
@media (max-width: 960px) {
  .icon {
    margin-top: 13px;
  }
}
</style>
