<template>
  <v-btn text color="grey darken-1" class="ml-1" v-bind="$attrs"
    ><slot>Cancel</slot></v-btn
  >
</template>

<script>
export default {}
</script>

<style></style>
