<template>
  <a :href="`tel:${phone}`" v-bind="$attrs" class="decoration-none">
    <slot v-bind:phone="phone">
      {{ phone }}
    </slot>
  </a>
</template>

<script>
export default {
  props: {
    phone: {
      type: String
    }
  }
}
</script>

<style></style>
