import Gallery from './Gallery.vue'
import GalleryGrey from './GalleryGrey.vue'
import GalleryGreyDot from './GalleryGreyDot.vue'
import GalleryWhite from './GalleryWhite.vue'
import GalleryPrimary from './GalleryPrimary.vue'

const Icons = {
  gallery: {
    component: Gallery
  },
  galleryGrey: {
    component: GalleryGrey
  },
  galleryGreyDot: {
    component: GalleryGreyDot
  },
  galleryWhite: {
    component: GalleryWhite
  },
  galleryPrimary: {
    component: GalleryPrimary
  }
}
export default Icons
