import Vue from 'vue'
import Axios from 'axios'
Vue.prototype.$axios = Axios
import config from '../config'

const api = config.api()

Vue.mixin({
  mounted() {
    Vue.prototype.$axios.defaults.baseURL = api.cloudFunctionAPI
  }
})
