import path from 'path'

function extension(filename) {
  return String(filename.split('.').pop()).toLowerCase()
}

function getExtension(name) {
  return name.split('.').pop() || ''
}

function addExtension(name, ext) {
  return `${name}.${ext}`
}

function removeExtension(name) {
  return String(name).substr(0, String(name).lastIndexOf('.'))
}

function updateExtension(name, ext) {
  return addExtension(removeExtension(name), ext)
}

function formatPath(str) {
  return str
    .split('/')
    .filter(x => x)
    .join('/')
}

const getFilePath = (folders, filename) => {
  return path.join(...folders, filename)
}

const indexOf = (files, id) => {
  return files.findIndex(item => item.id === id)
}

const updateObject = (data, payload) => {
  Object.keys(payload).forEach(key => {
    data[key] = payload[key]
  })
  return data
}

export {
  getExtension,
  extension,
  addExtension,
  removeExtension,
  updateExtension,
  formatPath,
  getFilePath,
  indexOf,
  updateObject
}
