<script>
import BaseForm from './GalleryBaseForm.vue'
export default {
  extends: BaseForm,
  props: {
    storage: {
      type: Object,
      required: true
    },
    selectedCategory: {
      type: String,
      required: true
    },
    userData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      updated: {
        thumbnail: false
      },
      retryCount: 0,
      MAX_RETRY: 10,
      RETRY_INTERVAL: 2000,
      firstHEICIndex: -1,
      elapsed: 0,
      intrvl: null
    }
  },
  computed: {
    photosUpdated() {
      return this.updated.thumbnail
    }
  },
  beforeDestroy() {
    if (this.intrvl) {
      clearInterval(this.intrvl)
    }
  },
  methods: {
    galleryPath(url, fileName = false) {
      const path = decodeURIComponent(new URL(url).pathname).match(
        /\/gallery\/(.*)/
      )
      if (fileName) {
        return path[1]
      } else {
        return path[0]
      }
    },

    getExtension(name) {
      return name.split('.').pop() || ''
    },
    addExtension(name, ext) {
      return `${name}.${ext}`
    },
    removeExtension(name) {
      return String(name).substr(0, String(name).lastIndexOf('.'))
    },
    updateExtension(name, ext) {
      return this.addExtension(this.removeExtension(name), ext)
    },

    async thumbnailURL(url, size = 200) {
      let path = this.galleryPath(url, true)

      const ext = this.getExtension(path)
      if (String(ext).toLowerCase() === 'heic') {
        path = this.updateExtension(path, 'jpeg')
      }

      const thumbnail = `thumbnails${size}/thumb@${size}_${path}`
      const ref = this.storage.ref(thumbnail)
      let downloadURL = ''
      downloadURL = await ref.getDownloadURL()
      return downloadURL
    },

    startTimer() {
      this.intrvl = setInterval(() => {
        this.elapsed += 1
      }, 1000)
    },

    resetImageProcess() {
      this.updated.thumbnail = false

      this.retryCount = 0
      this.firstHEICIndex = -1
      this.elapsed = 0
      if (this.intrvl) {
        clearInterval(this.intrvl)
      }
    },

    submitGallery() {
      return new Promise((resolve, reject) => {
        let type
        if (typeof this.formData.type === 'object') {
          type = this.formData.type.value
        } else {
          type = this.formData.type
        }
        const data = {
          ...this.formData,
          public: true,
          type,
          createdAt: new Date(),
          updatedAt: new Date()
        }
        if (this.userSetting.gps) {
          data.geolocation = this.geolocation || {}
        }
        if (data.files) {
          data.files.map(e => {
            delete e.file
            return e
          })
        }

        if (process.env.NODE_ENV === 'development') {
          console.log(data)
        }

        const userInfo = {
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName
        }

        this.galleryRef
          .add(data)
          .then(res => {
            this.success = `Your photo${
              data.files && data.files.length > 1 ? 's have' : ' has'
            } been submitted successfully`
            this.onCancel(false)
            this.loading = false
            this.clearAlert()

            this.$emit('success')
            this.$emit('playSound')
            this.$emit('update:lastCategory', data.type)
            this.$emit('update:userInfo', userInfo)
            this.$emit('update:category', type)

            this.resetImageProcess()
            resolve(res)
          })
          .catch(error => {
            this.error = 'Oops! Something went wrong!'
            this.loading = false
            this.clearAlert()
            this.$emit('error')
            this.resetImageProcess()
            console.warn(data)
            reject(error)
          })

        if (this.intrvl) {
          clearInterval(this.intrvl)
        }
      })
    },
    async updateThumbnail() {
      try {
        const thumbnailURL = await this.thumbnailURL(this.formData.files[0].url)
        if (thumbnailURL) {
          this.updated.thumbnail = true
          this.formData.thumbnail = thumbnailURL
        }
      } catch (error) {
        console.warn(error)
      }
    },
    async processThumbnails() {
      this.retryCount += 1

      await this.updateThumbnail()

      if (this.retryCount > this.MAX_RETRY) {
        this.submitGallery()
        return
      }

      // // DEUBG
      // console.log(
      //   `Submit\n\tRetries: ${this.retryCount}\n\tStatus: ${this.photosUpdated}`
      // )
      // console.log('Update: ', this.updated)
      // console.log('Formdata:', this.formData)
      // console.log('---------------------------------------')

      if (this.photosUpdated) {
        this.submitGallery()
      } else {
        setTimeout(() => {
          this.processThumbnails()
        }, this.RETRY_INTERVAL)
      }
    },

    submit() {
      this.loading = true
      this.startTimer()

      this.processThumbnails()
    }
  }
}
</script>

<style></style>
